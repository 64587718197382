import { Routes, Route, Navigate } from 'react-router-dom';
// components
import AuthorizedRoute from 'components/routes/authorizedRoute';
import UnauthorizedRoute from 'components/routes/unauthorizedRoute';
// pages
import Home from 'pages/home';
import Members from 'pages/members';
import OneMember from 'pages/members/one';
import Dummy from 'pages/dummy';
import Login from './pages/login';
import SuccessedLogin from './pages/successedLogin';

const Router = () => {
  return (
    <Routes>
      <Route path="/successed-login" element={<SuccessedLogin />} />

      <Route element={<UnauthorizedRoute />}>
        <Route path="/login" element={<Login />} />
      </Route>

      <Route element={<AuthorizedRoute />}>
        <Route path="/" element={<Home />} />
        <Route path="/members" element={<Members />} />
        <Route path="/members/one/:serviceName" element={<OneMember />} />
        <Route path="/settings" element={<Dummy title="Settings" />} />
      </Route>

      <Route path="*" element={<Navigate to="/members" replace />} />
    </Routes>
  );
};

export default Router;
