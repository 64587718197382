import React from 'react';
// components
import Modal from 'components/modal';
import Button from 'components/button';
// images
import avatarsGroup from 'assets/avatarsGroup.png';
// styles
import styles from './syncAllModal.module.css';

export interface SyncAllModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceName: string;
  onConfirmClick?: () => void;
}

const SyncAllModal: React.FC<SyncAllModalProps> = ({ isOpen, onClose, onConfirmClick, serviceName }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <img className={styles.image} src={avatarsGroup} alt="avatars-group" />
        <div className={styles.title}>Sync {serviceName} data with all other services data</div>
        <div className={styles.description}>
          As a result of synchronization, data on all other services will be replaced with data from the {serviceName}{' '}
          service
        </div>
        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} color="secondary" fullWidth>
            Cancel
          </Button>
          <Button onClick={onConfirmClick} fullWidth>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SyncAllModal;
