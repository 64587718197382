import React from 'react';
// libs
import { motion } from 'framer-motion';
// styles
import styles from './backdrop.module.css';

export interface BackdropProps {
  onClick: () => void;
  children: React.ReactNode | React.ReactNode[];
}

const Backdrop: React.FC<BackdropProps> = ({ onClick, children }) => {
  return (
    <motion.div
      className={styles.backdrop}
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
};

export default Backdrop;
