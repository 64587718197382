import React, { FC, ReactElement } from 'react';
import { Status } from 'store/members/types';
import Badge from 'components/badge';

type Props = {
  status: Status;
  className?: string;
};

const StatusBadge: FC<Props> = ({ status, className = '' }) => {
  const badgeType: Record<Status, ReactElement> = {
    active: (
      <Badge type="green" className={className}>
        Active
      </Badge>
    ),
    inactive: (
      <Badge type="yellow" className={className}>
        Inactive
      </Badge>
    ),
    'not registered': (
      <Badge type="gray" className={className}>
        Not Registered
      </Badge>
    ),
  };
  return badgeType[status];
};

export default StatusBadge;
