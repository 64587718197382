import React, { FC, ReactNode, useMemo } from 'react';
import cx from 'classnames';
import Sidebar from 'containers/sidebar';
import useUI from 'hooks/useUI';
import Title from 'components/pageTitle';
import styles from './wrapper.module.css';

type Props = {
  children?: ReactNode | ReactNode[];
  title?: string | ReactNode;
  pure?: boolean;
  className?: string;
};

const Wrapper: FC<Props> = ({ children, pure, className, title }) => {
  const { hideSidebar } = useUI();

  const titleElement = useMemo(() => {
    if (typeof title === 'string') return <Title>{title}</Title>;
    return title;
  }, [title]);

  return (
    <div className={cx(styles.wrapper, { [styles.withSmallSidebar]: hideSidebar })}>
      <Sidebar />
      <div className={cx(styles.child, { [styles.pure]: pure }, className)}>
        {title ? titleElement : null}
        {children}
      </div>
    </div>
  );
};

export default Wrapper;
