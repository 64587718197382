import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
// hooks
import useSlackChannels from 'hooks/api/useSlackChannels';
// components
import Button from 'components/button';
import Switch from 'components/switch';
import Field from '../../../field';
// styles
import styles from '../../services.module.css';

export interface Params {
  onDeleteButtonClick: () => void;
  onRegisterButtonClick: () => void;
  serviceExternalId: string;
  isRegisterButtonDisabled?: boolean;
  isDeleteButtonDisabled?: boolean;
  isArchiveDisabled?: boolean;
}

export type SlackChannel = {
  canBeLeft: boolean;
  created: number;
  description: string;
  id: string;
  isMember: boolean;
  name: string;
};

const useSlackIntegration = ({
  onDeleteButtonClick,
  onRegisterButtonClick,
  serviceExternalId,
  isRegisterButtonDisabled,
  isDeleteButtonDisabled,
}: Params) => {
  const [slackChannels, setSlackChannels] = useState<SlackChannel[]>();

  const { getSlackChannels, addToSlackChannel, removeFromSlackChannel } = useSlackChannels();

  useEffect(() => {
    if (serviceExternalId) {
      getSlackChannels(serviceExternalId).then(({ data }) => setSlackChannels(data));
    }
  }, [serviceExternalId]);

  const handleSlackChannelChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, channelId: string) => {
      if (event.target.checked) {
        addToSlackChannel({ channelId, userId: serviceExternalId });
      }

      if (!event.target.checked) {
        removeFromSlackChannel({ channelId, userId: serviceExternalId });
      }
    },
    [addToSlackChannel, removeFromSlackChannel, serviceExternalId],
  );

  const channelsIntegration = useMemo(() => {
    if (slackChannels?.length) {
      return slackChannels.map((channel) => {
        return {
          id: channel.id,
          name: (
            <Field className={styles.integrationsTableItemField} weight="medium">
              Channel: {channel.name}
            </Field>
          ),
          action: (
            <Switch
              checked={channel.isMember}
              disabled={!channel.canBeLeft}
              onChange={(event) => handleSlackChannelChange(event, channel.id)}
            />
          ),
        };
      });
    }
    return [];
  }, [handleSlackChannelChange, slackChannels]);

  const integrationInfo = useMemo(
    () =>
      [
        {
          id: '1',
          name: (
            <Field className={styles.integrationsTableItemField} weight="medium">
              Register / Delete
            </Field>
          ),
          action: (
            <div style={{ display: 'flex', gap: 16 }}>
              <Button onClick={onRegisterButtonClick} disabled={isRegisterButtonDisabled}>
                Register
              </Button>
              <Button color="secondary" onClick={onDeleteButtonClick} disabled={isDeleteButtonDisabled}>
                Delete
              </Button>
            </div>
          ),
        },
      ].concat(channelsIntegration),
    [onRegisterButtonClick, isRegisterButtonDisabled, onDeleteButtonClick, isDeleteButtonDisabled, channelsIntegration],
  );

  return { integrationInfo };
};

export default useSlackIntegration;
