import React, { FC, useMemo, useState, useEffect } from 'react';
import cx from 'classnames';
import styles from './avatar.module.css';

type Props = {
  alt: string;
  src?: string;
  className?: string;
  big?: boolean;
};

const Avatar: FC<Props> = ({ alt, className, src, big }) => {
  const [loaded, onChangeLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (src) {
      const image = new Image();
      image.setAttribute('src', src);
      image.onload = () => onChangeLoaded(true);
    }
  }, [src]);

  const placeholder = useMemo(() => {
    const abbr = alt
      .split(' ')
      .map((item: string) => item[0])
      .splice(0, 2)
      .join('');
    return <span className={styles.placeholder}>{abbr}</span>;
  }, [alt]);

  return (
    <div className={cx(styles.wrapper, className, { [styles.big]: big })}>
      {loaded && src ? <img draggable={false} loading="lazy" src={src} alt={alt} /> : placeholder}
    </div>
  );
};

export default Avatar;
