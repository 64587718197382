import { Action } from 'store/types';
import { UIState } from './types';
import { uiStateAdapter } from './adapters';
import {
  hideSidebarAction,
  setIsOAuthLinkLoading,
  setIsServiceActionLoading,
  setIsServiceFieldsLoading,
  setIsSyncAllLoading,
  setIsSyncFieldLoading,
  setSlackChannelsMeta,
  showSidebarAction,
} from './actions';

const initialStore = uiStateAdapter();

const reducer = (state: UIState = initialStore, action: Action) => {
  switch (action.type) {
    case hideSidebarAction.type: {
      return state.set('hideSidebar', true);
    }

    case showSidebarAction.type: {
      return state.set('hideSidebar', false);
    }

    case setIsOAuthLinkLoading.type: {
      return state.set('isOAuthLoading', action.payload);
    }

    case setIsSyncFieldLoading.type: {
      return state.set('isSyncFieldLoading', action.payload);
    }

    case setIsSyncAllLoading.type: {
      return state.set('isSyncAllLoading', action.payload);
    }

    case setIsServiceFieldsLoading.type: {
      return state.set('isServiceFieldsLoading', action.payload);
    }

    case setIsServiceActionLoading.type: {
      return state.set('isServiceActionLoading', action.payload);
    }

    case setSlackChannelsMeta.type: {
      return state.set('slackChannelsMeta', action.payload);
    }

    default: {
      return state;
    }
  }
};

export default reducer;
