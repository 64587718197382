import React, { FC, ReactNode } from 'react';
// libs
import cx from 'classnames';
// components
import Spinner from '../spinner';
// styles
import styles from './button.module.css';

export type ButtonColor = 'primary' | 'secondary';

export type ButtonProps = {
  className?: string;
  color?: ButtonColor;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
  children: string | string[] | ReactNode | ReactNode[];
};

const Button: FC<ButtonProps> = ({
  className,
  children,
  startIcon,
  endIcon,
  disabled,
  onClick,
  fullWidth,
  isLoading,
  color = 'primary',
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled || isLoading}
      className={cx(styles.button, styles[color], { [styles.fullWidth]: fullWidth }, className)}
      tabIndex={0}
    >
      {isLoading ? <Spinner className={styles.spinner} /> : null}
      <div className={cx(styles.container, { [styles.loading]: isLoading })}>
        {startIcon ? <span className={cx(styles.icon, styles.startIcon)}>{startIcon}</span> : null}
        {children}
        {endIcon ? <span className={cx(styles.icon, styles.endIcon)}>{endIcon}</span> : null}
      </div>
    </button>
  );
};

export default Button;
