import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
// libs
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { CellProps, Column, Row } from 'react-table';
// components
import Title from 'components/pageTitle';
import MembersTable from 'pages/members/components/membersTable';
import MembersTableFilters from 'pages/members/components/membersTableFilters';
import Avatar from 'components/avatar';
import Badge from 'components/badge';
import Input from 'components/input';
import Spinner from 'components/spinner';
import Wrapper from 'containers/wrapper';
import StatusBadge from 'containers/status';
import Social, { Icons } from 'components/social';
// constants
import { FilterType } from 'pages/members/components/membersTableFilters/constants';
// providers
import { useMembersTableFiltersContext } from 'pages/members/components/membersTableFilters/providers/membersTableFiltersProvider';
// hooks
import useMembers from 'hooks/api/useMembers';
import useUI from 'hooks/useUI';
import useProfile from 'hooks/api/useProfile';
// helpers
import { generateMemberQuery } from 'helpers/generateQuery';
// types
import { Member, ServiceKey, Status } from 'store/members/types';
// styles
import styles from './members.module.css';

const StatusColumnFilter = ({
  column: { filterValue, setFilter },
}: {
  column: { filterValue: Status | ''; setFilter: (status: Status | '') => void };
}) => {
  const getStatus = useCallback(() => {
    let status: Status | '' = '';

    if (!filterValue) {
      status = 'active';
    }

    if (filterValue === 'active') {
      status = 'inactive';
    }

    if (filterValue === 'inactive') {
      status = 'not registered';
    }

    return status;
  }, [filterValue]);

  return <div className={styles.tableHeaderFilter} onClick={() => setFilter(getStatus())} />;
};

// TODO: move members table data and column props to its own hook or to membersTable component. Refactor data and column formation logic
const Members: FC = () => {
  const { list, loading, getList } = useMembers();

  const { profile } = useProfile();

  const { selectedFilterType, selectedFilters } = useMembersTableFiltersContext();

  const { hideSidebar } = useUI();

  const navigate = useNavigate();

  useEffect(() => {
    getList();
  }, []);

  const [search, onChangeSearch] = useState<string>('');

  const title = useMemo(() => {
    return (
      <div className={styles.header}>
        <Title className={styles.title}>Team members</Title>
        <Badge type="red">
          {list.length} {list.length !== 1 ? 'users' : 'user'}
        </Badge>
        <div className={styles.searchWrapper}>
          <Input
            icon="search"
            placeholder="User name or email..."
            name="members-search"
            value={search}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeSearch(event.target.value)}
          />
        </div>
      </div>
    );
  }, [search, list, onChangeSearch]);

  const showSelectedStatusFirst = (rows: Array<Row>, ids: Array<ServiceKey>, filterValue: Status | '') => {
    const currentId = ids[0];
    const copiedRows = [...rows];
    return copiedRows.sort((a: any) => {
      if (a.values[currentId].status === filterValue) {
        return -1;
      }
      if (a.values[currentId].status !== filterValue) {
        return 1;
      }
      return 0;
    });
  };

  const filteredList = useMemo(() => {
    const filterByNameAndEmail = (member: Member) => {
      const searchText = search.toLowerCase().replace(/ /g, '');
      const nameText = member.name.toLowerCase().replace(/ /g, '');
      const emailText = member.email.toLowerCase().replace(/ /g, '');
      return nameText.includes(searchText) || emailText.includes(searchText);
    };

    if (!selectedFilters?.length && !search) {
      return list;
    }

    if (!selectedFilters?.length) {
      return list.filter(filterByNameAndEmail);
    }

    return list
      .filter((member: Member) => {
        if (selectedFilterType?.value === FilterType.ANY) {
          return selectedFilters.some((filter) => {
            if (filter.value.status === 'not registered') {
              return !member.services?.[filter.value.service]?.status;
            }
            return Object.entries(member.services).some(([serviceKey, service]) => {
              const status = service.status ?? 'not registered';
              return serviceKey === filter.value.service && filter.value.status === status;
            });
          });
        }

        if (selectedFilterType?.value === FilterType.ALL) {
          return selectedFilters.every((filter) => {
            if (filter.value.status === 'not registered') {
              return !member.services?.[filter.value.service]?.status;
            }
            return Object.entries(member.services).some(([serviceKey, service]) => {
              return serviceKey === filter.value.service && filter.value.status === service.status;
            });
          });
        }

        return true;
      })
      .filter(filterByNameAndEmail);
  }, [list, search, selectedFilterType?.value, selectedFilters]);

  const unauthenticatedServices = useMemo(() => {
    if (profile?.serviceAuth) {
      return Object.entries(profile?.serviceAuth)
        .map(([key, value]) => (value ? null : key))
        .filter((service) => service !== null);
    }
    return [];
  }, [profile?.serviceAuth]);

  const data = useMemo(
    () =>
      filteredList.map((member: Member) => {
        if (profile?.showServices) {
          const services = Array.from(profile.showServices);

          const statusBadges = services
            .filter(({ serviceType }) => !unauthenticatedServices?.includes(serviceType as string))
            .reduce((prevValue, { serviceKey, serviceTitle }) => {
              const status = member?.services?.[serviceKey as ServiceKey]?.status ?? 'not registered';
              return {
                ...prevValue,
                [serviceKey as string]: {
                  title: serviceTitle,
                  value: <StatusBadge status={status} />,
                  status,
                },
              };
            }, {});

          const personalInfo = {
            personalInfo: {
              title: 'Person info',
              value: (
                <div className={styles.user}>
                  <Avatar className={styles.avatar} src={member.avatar.image_72} alt={member.name} />
                  <div className={styles.info}>
                    <div className={styles.email}>
                      <strong>{member.name}</strong>
                    </div>
                    <div className={styles.name}>{member.email || 'no email data'}</div>
                  </div>
                </div>
              ),
              name: member.name,
            },
          };

          return { ...personalInfo, ...statusBadges } as Record<
            ServiceKey | 'personalInfo',
            { title: string; value: JSX.Element }
          >;
        }

        return [];
      }),
    [filteredList, profile.showServices, unauthenticatedServices],
  );

  const columns = useMemo<Column[]>(() => {
    if (profile?.showServices) {
      const services = Array.from(profile.showServices);
      const servicesColumns = services
        .filter(({ serviceType }) => !unauthenticatedServices?.includes(serviceType as string))
        .map(({ serviceType, serviceKey, serviceTitle }) => {
          const accessor = serviceKey as string;

          const activeUsersCount = filteredList.reduce((prevValue, member: Member) => {
            const status = member?.services?.[serviceKey as ServiceKey]?.status ?? 'not registered';
            if (status === 'active') {
              return prevValue + 1;
            }
            return prevValue;
          }, 0);

          return {
            // eslint-disable-next-line react/no-unstable-nested-components
            Header: () => (
              <div className={styles.thead} key={serviceTitle}>
                <Social small icon={serviceType as Icons} className={styles.icon} />
                {serviceTitle} {`(${activeUsersCount})`}
              </div>
            ),
            accessor,
            disableSortBy: true,
            Filter: StatusColumnFilter,
            filter: showSelectedStatusFirst,
            Cell: (props: CellProps<{}>) => props.value.value,
            sortType: (a, b) => {
              if (a.values[accessor].status < b.values[accessor].status) {
                return -1;
              }
              if (a.values[accessor].status > b.values[accessor].status) {
                return 1;
              }
              return 0;
            },
          } as Column;
        });

      const personalInfoColumn = {
        Header: 'Person info',
        accessor: 'personalInfo',
        Cell: (props) => props.value.value,
        sortType: (a, b) => {
          if (a.values.personalInfo.name < b.values.personalInfo.name) {
            return -1;
          }
          if (a.values.personalInfo.name > b.values.personalInfo.name) {
            return 1;
          }
          return 0;
        },
      } as Column;

      return [personalInfoColumn, ...servicesColumns];
    }

    return [] as Column[];
  }, [filteredList, profile.showServices, unauthenticatedServices]);

  const onClickMember = useCallback(
    (id: number) => {
      const chosen: Member = filteredList[id];
      if (chosen) {
        const query = generateMemberQuery(chosen);
        navigate(`/members/one/gSuite?${query}`);
      }
    },
    [navigate, filteredList],
  );

  return (
    <Wrapper title={title} className={styles.wrapper}>
      <div className={styles.membersTableWrapper}>
        <MembersTableFilters />
        <MembersTable
          sticky
          onClickRow={onClickMember}
          empty={search ? 'No members to search for' : "You don't have any team members yet"}
          className={styles.table}
          data={data}
          columns={columns}
        />
      </div>
      {loading ? (
        <Spinner text="Loading" className={cx(styles.spinner, { [styles.smallSidebarSpinner]: hideSidebar })} />
      ) : null}
    </Wrapper>
  );
};

export default Members;
