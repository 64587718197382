import React, { FC, ReactNode } from 'react';
import cx from 'classnames';
import styles from './badge.module.css';

export type BadgeType = 'green' | 'red' | 'gray' | 'yellow';

type Props = {
  className?: string;
  type?: BadgeType;
  children: string | string[] | ReactNode | ReactNode[];
};

const Badge: FC<Props> = ({ className = '', type = 'green', children }) => {
  return <div className={cx(styles.wrapper, styles[type], className)}>{children}</div>;
};

export default Badge;
