import React, { FC, useMemo } from 'react';
// libs
import cx from 'classnames';
// icons
import gSuite from './assets/gSuite.svg';
import github from './assets/github.svg';
import excel from './assets/excel.svg';
import float from './assets/float.svg';
import jira from './assets/jira.svg';
import notion from './assets/notion.svg';
import slack from './assets/slack.svg';
import gSuiteInactive from './assets/gSuiteInactive.svg';
import githubInactive from './assets/githubInactive.svg';
import excelInactive from './assets/excelInactive.svg';
import floatInactive from './assets/floatInactive.svg';
import jiraInactive from './assets/jiraInactive.svg';
import notionInactive from './assets/notionInactive.svg';
import slackInactive from './assets/slackInactive.svg';
import broSync from './assets/broSync.svg';
import broReports from './assets/broReports.svg';
import broSyncInactive from './assets/broSyncInactive.svg';
import broReportsInactive from './assets/broReportsInactive.svg';
// styles
import styles from './icon.module.css';

export type Icons =
  | 'gSuite'
  | 'slack'
  | 'notion'
  | 'github'
  | 'float'
  | 'jira'
  | 'excel'
  | 'broSync'
  | 'broReports'
  | 'googleSheets'
  | 'gSuiteInactive'
  | 'githubInactive'
  | 'excelInactive'
  | 'floatInactive'
  | 'jiraInactive'
  | 'notionInactive'
  | 'slackInactive'
  | 'googleSheetsInactive';

type Props = {
  className?: string;
  icon: Icons;
  small?: boolean;
};

type IconType = {
  [key in Icons]: string;
};

const Social: FC<Props> = ({ className, small, icon }) => {
  const icons: IconType = useMemo(
    () => ({
      gSuite,
      slack,
      github,
      float,
      notion,
      jira,
      excel,
      broSync,
      broReports,
      googleSheets: excel,
      gSuiteInactive,
      githubInactive,
      excelInactive,
      floatInactive,
      jiraInactive,
      notionInactive,
      slackInactive,
      broSyncInactive,
      broReportsInactive,
      googleSheetsInactive: excelInactive,
    }),
    [],
  );

  return (
    <span className={cx(className, styles.icon, { [styles.small]: small })}>
      <img src={icons[icon]} alt={icon} draggable={false} />
    </span>
  );
};

export default Social;
