import { ImmutableObject } from 'seamless-immutable';
import { Trim } from '../../helpers/typesHelpters';

export const SERVICE_TYPES = [
  'broReports',
  'broSync',
  'float',
  'github',
  'googleSheets',
  'gSuite',
  'jira',
  'notion',
  'slack',
] as const;

export type ServiceType = typeof SERVICE_TYPES[number];

export type Status = 'active' | 'inactive' | 'not registered';

export type Resource = string;

export type ImageSize =
  | 'image_original'
  | 'image_24'
  | 'image_32'
  | 'image_48'
  | 'image_72'
  | 'image_192'
  | 'image_512'
  | 'image_1024';

export type Avatars = Array<Record<ImageSize, string>>;

export type ServiceKey = Trim<`${ServiceType}${Resource}`>;

export type Avatar = {
  image_original: string;
  image_24: string;
  image_32: string;
  image_48: string;
  image_72: string;
  image_192: string;
  image_512: string;
  image_1024: string;
};

export type Service = {
  externalIdName?: string;
  externalIdValue?: string;
  service: Service;
  status: Status;
  resource: Resource;
};

export type Member = ImmutableObject<{
  name: string;
  email: string;
  avatar: Avatar;
  services: Record<ServiceKey, Service>;
}>;

export type ApiMember = {
  name: string;
  email: string;
  avatars: Avatars;
  pages: Record<ServiceKey, Service>;
};

export type SyncStatuses = {
  [key in ServiceKey]?: boolean;
};

export type ApiSyncStatuses = {
  [key in ServiceKey]?: boolean;
};

export type FieldType = 'Default' | 'Phone' | 'Email' | 'Date' | 'Avatar' | 'hidden';

export type ServiceDataField = { syncStatuses: SyncStatuses; value: string; type: FieldType };

export type ApiServiceDataField = { syncStatuses: ApiSyncStatuses; value: string; type: FieldType };

export type ServiceDataFields = Record<string, ServiceDataField>;

export type CommonServiceDataFields = {
  name: string;
  email: string;
  externalIdName: string;
  externalIdValue: string;
  service: ServiceType;
  status: Status;
  avatar: Avatar;
  resource: Resource;
};

export type ServiceData<D extends ServiceDataFields> = CommonServiceDataFields & {
  data: D;
};

export type EmptyServiceData = Omit<CommonServiceDataFields, 'resource'> & {
  data: undefined;
  resource: Resource | '';
};

export type ApiServiceData<D extends ServiceDataFields> = Omit<ServiceData<D>, 'avatar'> & { avatars: Avatars };

export type CommonData = {
  [index: string]: ServiceDataField;
};

export type ApiCommonData = {
  [index: string]: ApiServiceDataField;
};

export type CommonServiceData = ServiceData<CommonData>;

export type ApiCommonServiceData = ApiServiceData<ApiCommonData>;

export type EmptyService = EmptyServiceData;

export type ShowMember = {
  [key in ServiceKey]?: CommonServiceData;
};

export type ApiShowMember = {
  [key in ServiceKey]?: ApiCommonServiceData;
};

export type MembersState = ImmutableObject<{
  list: Member[];
  one: ShowMember;
  isLoading: boolean;
}>;
