import React, { useCallback, useMemo } from 'react';
// hooks
import useProfile from 'hooks/api/useProfile';
// components
import GSuite from '../one/components/services/gSuite';
import Slack from '../one/components/services/slack';
import Notion from '../one/components/services/notion';
import Float from '../one/components/services/float';
import Github from '../one/components/services/github';
import Jira from '../one/components/services/jira';
import GoogleSheets from '../one/components/services/googleSheets';
import Default from '../one/components/services/default';
import { Tab } from '../../../components/tabs';
import { Resource, ServiceKey, ServiceType } from '../../../store/members/types';
import { Icons } from '../../../components/social';
// constants
import { SERVICE_TYPE_TO_TITLE } from '../../../constants/general';

export type ServiceProps = {
  serviceResource: Resource;
  serviceTitle: string;
  serviceKey: ServiceKey;
  serviceType: ServiceType;
};

const useServiceTabs = () => {
  const { profile } = useProfile();

  const getElement = useCallback((serviceType: ServiceType, serviceProps: ServiceProps) => {
    switch (serviceType) {
      case 'float':
        return <Float {...serviceProps} />;
      case 'github':
        return <Github {...serviceProps} />;
      case 'googleSheets':
        return <GoogleSheets {...serviceProps} />;
      case 'gSuite':
        return <GSuite {...serviceProps} />;
      case 'jira':
        return <Jira {...serviceProps} />;
      case 'notion':
        return <Notion {...serviceProps} />;
      case 'slack':
        return <Slack {...serviceProps} />;
      default:
        return <Default {...serviceProps} />;
    }
  }, []);

  const serviceTabs = useMemo(() => {
    if (profile?.showServices) {
      const showServices = Array.from(profile.showServices);
      return showServices.map(({ serviceType, serviceKey, serviceTitle, resource }) => {
        if (serviceType && serviceKey && serviceTitle && resource) {
          return {
            id: serviceKey,
            icon: serviceType as Icons,
            label: serviceType !== serviceKey ? resource : SERVICE_TYPE_TO_TITLE[serviceType as ServiceType] || '',
            element: getElement(serviceType as ServiceType, {
              serviceResource: resource,
              serviceTitle,
              serviceKey,
              serviceType,
            }) as JSX.Element,
          };
        }

        return {};
      }) as Tab[];
    }
    return [] as Tab[];
  }, [getElement, profile.showServices]);

  return { serviceTabs };
};

export default useServiceTabs;
