import React, { FC, ReactElement, useMemo } from 'react';
// libs
import cx from 'classnames';
// styles
import styles from './icon.module.css';
// icons
import Team from './assets/team';
import Cog from './assets/cog';
import Exit from './assets/exit';
import Search from './assets/search';
import Spinner from './assets/spiner';
import Cross from './assets/cross';
import FlagGb from './assets/flagGb';
import Help from './assets/help';
import Refresh from './assets/refresh';
import Envelope from './assets/envelope';
import ArrowUpRight from './assets/arrowUpRight';
import Calendar from './assets/calendar';
import Flag from './assets/flag';
import FlagUa from './assets/flagUa';

export type Icons =
  | 'team'
  | 'cog'
  | 'spinner'
  | 'exit'
  | 'search'
  | 'cross'
  | 'flagGb'
  | 'help'
  | 'refresh'
  | 'envelope'
  | 'arrowUpRight'
  | 'calendar'
  | 'flag'
  | 'flagUa';

type Props = {
  className?: string;
  icon: Icons;
};

type IconType = {
  [key in Icons]: ReactElement;
};

const Icon: FC<Props> = ({ className, icon }) => {
  const icons: IconType = useMemo(
    () => ({
      team: <Team />,
      cog: <Cog />,
      spinner: <Spinner />,
      search: <Search />,
      exit: <Exit />,
      cross: <Cross />,
      flagGb: <FlagGb />,
      help: <Help />,
      refresh: <Refresh />,
      envelope: <Envelope />,
      arrowUpRight: <ArrowUpRight />,
      calendar: <Calendar />,
      flag: <Flag />,
      flagUa: <FlagUa />,
    }),
    [],
  );

  return <span className={cx(className, styles.icon, styles[icon])}>{icons[icon]}</span>;
};

export default Icon;
