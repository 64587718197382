const Refresh = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.0443 9.74421C15.8128 11.9191 14.5805 13.9572 12.5413 15.1345C9.15342 17.0905 4.82132 15.9298 2.86531 12.5419L2.65698 12.181M1.95527 8.25584C2.18677 6.08092 3.4191 4.04282 5.45825 2.86551C8.84616 0.909505 13.1783 2.07029 15.1343 5.45819L15.3426 5.81904M1.91113 14.055L2.52118 11.7783L4.79788 12.3884M13.2022 5.61167L15.4789 6.22172L16.0889 3.94501"
      stroke="#667085"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Refresh;
