import React, { useCallback, useMemo } from 'react';
// libs
import cx from 'classnames';
import { Tooltip as ReactTooltip } from 'react-tooltip';
// types
import { ServiceKey, ServiceType, SERVICE_TYPES } from 'store/members/types';
import { ServiceInfo, SyncFieldClickParams } from 'pages/members/types';
// hooks
import useProfile from 'hooks/api/useProfile';
// components
import Title from 'components/pageTitle';
import Icon from 'components/icon';
import Button from 'components/button';
import Social, { Icons } from 'components/social';
// constants
import { SERVICE_TYPE_TO_TITLE } from 'constants/general';
// styles
import styles from '../../../services.module.css';

export interface InfoSectionProps {
  info: ServiceInfo;
  serviceTitle: string;
  onServiceClick?: (params: SyncFieldClickParams) => void;
  onSyncAllClick?: () => void;
}

const InfoSection: React.FC<InfoSectionProps> = ({
  info,
  serviceTitle,
  onSyncAllClick = () => {},
  onServiceClick = () => {},
}) => {
  const { profile } = useProfile();

  const syncButtonTooltipContent = useMemo(
    () => `
      <div>
        <div class="${styles.tooltipTitle}">Data synchronization</div>
        <div class="${styles.tooltipDescription}">
          Active icons indicate services whose data is out of sync with the current one. To synchronize them, click on
          the appropriate icon below. Or you can click the SYNC button to synchronize all fields at once.
        </div>
      </div>`,
    [],
  );

  const getSingleIconTooltipContent = useCallback(
    (title: string) =>
      `
      <div>
        <div class="${styles.tooltipTitle}">${title}</div>
      </div>`,
    [],
  );

  const handleServiceClick = useCallback(
    ({
        isInactive,
        serviceTitle,
        serviceType,
        resource,
        serviceKey,
        field,
      }: SyncFieldClickParams & { isInactive: boolean }) =>
      () => {
        if (!isInactive) {
          onServiceClick({ serviceType, serviceTitle, field, serviceKey, resource });
        }
      },
    [onServiceClick],
  );

  const rows = useMemo(() => {
    return info.map(({ id, fieldName, fieldData, availableIn, fieldOriginalName }) => (
      <React.Fragment key={id}>
        <div className={styles.infoTableItem}>{fieldName}</div>
        <div className={styles.infoTableItem}>{fieldData}</div>
        <div className={cx(styles.infoTableItem, styles.last)}>
          {Object.entries(availableIn).map(([key, value]) => {
            const showServices = Array.from(profile.showServices);

            const currentShowService = showServices.find((service) => service.serviceKey === key);

            const isInactive =
              value ||
              !(profile.serviceAuth[key as ServiceType] === undefined ? true : profile.serviceAuth[key as ServiceType]);

            const currentServiceType = SERVICE_TYPES.find((serviceType) => key.includes(serviceType)) as ServiceType;

            const splitServiceKey = key.split(currentServiceType);

            const resource = splitServiceKey?.[1] ? splitServiceKey[1] : currentServiceType;

            const currentResource = currentShowService?.resource ? currentShowService.resource : resource;

            const isTypeEqualsToResource = currentServiceType === currentResource;

            const serviceTypeTitle = SERVICE_TYPE_TO_TITLE[currentServiceType];

            const label = isTypeEqualsToResource ? null : currentResource;

            const currentServiceTitle = isTypeEqualsToResource
              ? serviceTypeTitle
              : `${serviceTypeTitle} ${currentResource}`;

            const icon = isInactive ? `${currentServiceType}Inactive` : currentServiceType;

            const tooltipContent = getSingleIconTooltipContent(currentServiceTitle);

            return (
              <React.Fragment key={key}>
                <ReactTooltip
                  className={styles.tooltip}
                  anchorId={`${key}-${fieldOriginalName}`}
                  html={tooltipContent}
                />
                <button
                  id={`${key}-${fieldOriginalName}`}
                  type="button"
                  key={key}
                  className={cx(styles.socialLabel, { [styles.inactive]: isInactive })}
                  onClick={handleServiceClick({
                    isInactive,
                    serviceType: currentServiceType,
                    serviceTitle: currentServiceTitle,
                    resource: currentShowService?.resource ? currentShowService.resource : currentResource,
                    serviceKey: key as ServiceKey,
                    field: fieldOriginalName,
                  })}
                >
                  <Social key={key} className={styles.infoTableSocial} icon={icon as Icons} />
                  {label}
                </button>
              </React.Fragment>
            );
          })}
        </div>
      </React.Fragment>
    ));
  }, [getSingleIconTooltipContent, handleServiceClick, info, profile.serviceAuth, profile.showServices]);

  const isSyncAllDisabled = useMemo(
    () => info.every(({ availableIn }) => Object.values(availableIn).every((value) => value)),
    [info],
  );

  return (
    <div>
      <div className={styles.headContainer}>
        <ReactTooltip className={styles.tooltip} anchorId="sync-button-tooltip" html={syncButtonTooltipContent} />
        <Title className={cx(styles.title, styles.withMargin)}>{`${serviceTitle} info`}</Title>

        <div className={styles.syncContainer}>
          <Button
            color="secondary"
            startIcon={<Icon icon="refresh" />}
            onClick={onSyncAllClick}
            className={cx(styles.sync, { [styles.disabled]: isSyncAllDisabled })}
            disabled={isSyncAllDisabled}
          >
            Sync
          </Button>
          <div id="sync-button-tooltip">
            <Icon icon="help" className={styles.helpIcon} />
          </div>
        </div>
      </div>
      <div className={styles.infoTable}>{rows}</div>
    </div>
  );
};

export default InfoSection;
