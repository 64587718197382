import React from 'react';
// types
import { Resource, ServiceKey, ServiceType } from 'store/members/types';
// hooks
import useService from '../hooks/useService';
import useGithubIntegration from '../hooks/integrations/useGithubIntegration';
// components
import ServiceInfo from '../common/serviceInfo';
import Button from '../../../../../../components/button';
// styles
import styles from '../services.module.css';

export interface GithubInfoProps {
  serviceResource: Resource;
  serviceTitle: string;
  serviceKey: ServiceKey;
  serviceType: ServiceType;
}

const Github: React.FC<GithubInfoProps> = ({ serviceResource, serviceTitle, serviceKey, serviceType }) => {
  const {
    shouldAuth,
    info,
    handleSyncFieldClick,
    handleSyncAllModalOpen,
    renderModals,
    selectedSyncService,
    handleSyncFieldConfirm,
    handleSyncAllConfirm,
    serviceFieldsData,
    handleAuthenticateClick,
    isOAuthLinkLoading,
  } = useService({ serviceType, serviceResource, serviceTitle, serviceKey });

  const { integrationInfo } = useGithubIntegration({
    isGiveAccessDisabled: true,
  });

  return (
    <div className={styles.wrapper}>
      {!shouldAuth ? (
        <ServiceInfo
          serviceTitle={serviceTitle}
          info={info}
          integrationInfo={integrationInfo}
          onServiceClick={handleSyncFieldClick}
          onSyncAllClick={handleSyncAllModalOpen}
        />
      ) : (
        <Button onClick={handleAuthenticateClick} isLoading={isOAuthLinkLoading}>
          Authenticate
        </Button>
      )}
      {renderModals({
        syncService: selectedSyncService,
        onSyncConfirm: handleSyncFieldConfirm,
        onSyncAllConfirm: handleSyncAllConfirm,
        registerServiceFieldsData: serviceFieldsData,
      })}
    </div>
  );
};

export default Github;
