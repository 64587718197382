import React, { useCallback, useRef } from 'react';
// libs
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
// hooks
import useOnClickOutside from 'hooks/useOnClickOutside';
// styles
import styles from './popover.module.css';

interface PopoverMenuProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  content: React.ReactNode;
  button?: React.ReactNode;
  className?: string;
}

const motionDivProps = {
  initial: {
    opacity: 0,
    y: -5,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  transition: {
    type: 'spring',
    bounce: 0,
    duration: 0.3,
  },
};

const PopoverMenu: React.FC<PopoverMenuProps> = ({ isOpen, setIsOpen, button, content, className = '' }) => {
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const buttonWrapperRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);

  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  useOnClickOutside([contentWrapperRef, buttonWrapperRef], handleClose);

  return (
    <div className={styles.wrapper}>
      <div onClick={handleButtonClick} ref={buttonWrapperRef}>
        {button || null}
      </div>
      <AnimatePresence initial={false} mode="wait">
        {isOpen && (
          <motion.div {...motionDivProps} className={cx(styles.popover, className)}>
            <div ref={contentWrapperRef}>{content}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PopoverMenu;
