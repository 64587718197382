import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
// libs
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// containers
import Wrapper from 'containers/wrapper';
// hooks
import useMembers from 'hooks/api/useMembers';
import useSyncField from 'hooks/api/useSyncField';
import useProfile from 'hooks/api/useProfile';
import useSyncAll from 'hooks/api/useSyncAll';
import useSlackChannels from 'hooks/api/useSlackChannels';
import useServiceTabs from 'pages/members/hooks/useServiceTabs';
// types
import { ShowMember } from 'store/members/types';
// components
import Avatar from 'components/avatar';
import StatusBadge from 'containers/status';
import Spinner from 'components/spinner';
import Tabs from 'components/tabs';
// helpers
import { getEmptyService } from 'helpers/servicesHelpers';
// styles
import styles from './one.module.css';

const OneMember: FC = () => {
  const navigate = useNavigate();

  const { search } = useLocation();

  const { loading, clearOne, one, getOne } = useMembers();

  const { serviceName } = useParams();

  const { isLoading: isSyncInProcess } = useSyncField();

  const { isLoading: isSyncAllInProcess } = useSyncAll();

  const { isLoading: isProfileLoading } = useProfile();

  const { slackChannelsMeta } = useSlackChannels();

  const { serviceTabs } = useServiceTabs();

  const [selectedTab, setSelectedTab] = useState(serviceName);

  const handleTabChange = useCallback(
    (id: string) => {
      setSelectedTab(id);
      navigate(`/members/one/${id}${search}`, { replace: true });
    },
    [navigate, search],
  );

  useEffect(() => {
    getOne(search);
    return () => {
      clearOne();
    };
  }, [search]);

  const selectedService = useMemo(() => {
    const service = one[serviceName as keyof ShowMember];
    if (service) {
      return service;
    }
    return getEmptyService(serviceName);
  }, [one, serviceName]);

  const isTabsLoading = useMemo(
    () => isProfileLoading || loading || isSyncInProcess || isSyncAllInProcess,
    [isProfileLoading, isSyncAllInProcess, isSyncInProcess, loading],
  );

  const showSpinner = useMemo(
    () =>
      isProfileLoading ||
      loading ||
      isSyncInProcess ||
      isSyncAllInProcess ||
      Object.values(slackChannelsMeta).some((item) => item),
    [isProfileLoading, isSyncAllInProcess, isSyncInProcess, loading, slackChannelsMeta],
  );

  return (
    <Wrapper pure>
      <div className={styles.wrapper}>
        <div className={styles.header} />
        <div className={styles.info}>
          <Avatar className={styles.avatar} src={one?.slack?.avatar?.image_192} big alt={selectedService?.name || ''} />
          <div className={styles.nameWrapper}>
            <h4 className={styles.name}>{selectedService?.name}</h4>
            <StatusBadge status={selectedService?.status} className={styles.status} />
          </div>
        </div>
        <div className={styles.contentWrapper}>
          {isTabsLoading ? null : <Tabs list={serviceTabs} activeTab={selectedTab} onTabChange={handleTabChange} />}
        </div>
        {showSpinner ? <Spinner className={styles.spinner} /> : null}
      </div>
    </Wrapper>
  );
};

export default OneMember;
