import React, { MouseEvent, useCallback, useEffect } from 'react';
// libs
import { AnimatePresence, motion } from 'framer-motion';
// components
import Backdrop from '../backdrop';
import Icon from '../icon';
// styles
import styles from './modal.module.css';

const variants = {
  initial: {
    y: 50,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 50,
    opacity: 0,
  },
};

const transition = {
  type: 'spring',
  bounce: 0,
  duration: 0.4,
};

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode | React.ReactNode[];
}

const Modal: React.FC<ModalProps> = ({ open, onClose, children }) => {
  const handleModalWrapperClick = useCallback((event: MouseEvent<HTMLDivElement>) => event.stopPropagation(), []);

  const handleEscapeKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey, false);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey, false);
    };
  }, [handleEscapeKey]);

  return (
    <AnimatePresence initial={false} mode="wait">
      {open ? (
        <Backdrop onClick={onClose}>
          <motion.div
            onClick={handleModalWrapperClick}
            variants={variants}
            transition={transition}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <div className={styles.content}>
              <button type="button" className={styles.close} onClick={onClose}>
                <Icon icon="cross" />
              </button>
              {children}
            </div>
          </motion.div>
        </Backdrop>
      ) : null}
    </AnimatePresence>
  );
};

export default Modal;
