import { createAction } from 'helpers/actions';

export const hideSidebarAction = createAction('ui/hide_sidebar');
export const showSidebarAction = createAction('ui/show_sidebar');
export const setIsOAuthLinkLoading = createAction('ui/setIsOAuthLinkLoading');
export const setIsSyncFieldLoading = createAction('ui/setIsSyncFieldLoading');
export const setIsSyncAllLoading = createAction('ui/setIsSyncAllLoading');
export const setIsServiceFieldsLoading = createAction('ui/setIsServiceFieldsLoading');
export const setIsServiceActionLoading = createAction('ui/setIsServiceActionLoading');

export const setSlackChannelsMeta = createAction('ui/setSlackChannelsMeta');
