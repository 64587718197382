import { useCallback } from 'react';
// libs
import { useSelector, useDispatch } from 'react-redux';
// types
import { AxiosResponse } from 'axios';
// store
import { isLoadingSelector, membersListSelector, oneMemberSelector } from 'store/members/selector';
import { setMembersList, setMember, clearMember, setIsLoading } from 'store/members/actions';
import { Member, ServiceKey, ShowMember } from 'store/members/types';
// hooks
import useApi from './useApi';

type IUseMembers = {
  list: Member[];
  loading: boolean;
  getList: () => void;
  getOne: (query: string) => void;
  clearOne: () => void;
  one: ShowMember;
  getMemberIdentifiers: () => { [key in ServiceKey]: string };
};

const useMembers = (): IUseMembers => {
  const dispatch = useDispatch();

  const list = useSelector(membersListSelector);

  const one = useSelector(oneMemberSelector);

  const loading = useSelector(isLoadingSelector);

  const { getOneMember, getTeamMembers } = useApi();

  const getList = useCallback(() => {
    dispatch(setIsLoading(true));
    getTeamMembers()
      .then((response: AxiosResponse) => {
        dispatch(setMembersList(response.data));
      })
      .catch((error) => error)
      .finally(() => dispatch(setIsLoading(false)));
  }, [dispatch, getTeamMembers]);

  const getOne = useCallback(
    (query: string) => {
      dispatch(setIsLoading(true));
      getOneMember(query)
        .then((response: AxiosResponse) => {
          dispatch(setMember(response.data));
        })
        .catch((error) => error)
        .finally(() => dispatch(setIsLoading(false)));
    },
    [dispatch, getOneMember],
  );

  const getMemberIdentifiers = useCallback(() => {
    return Object.entries(one).reduce((previousValue, [key, value]) => {
      if (value && value?.externalIdValue) {
        return { ...previousValue, [key]: String(value.externalIdValue) };
      }
      return { ...previousValue };
    }, {}) as { [key in ServiceKey]: string };
  }, [one]);

  const clearOne = useCallback(() => {
    dispatch(clearMember());
  }, [dispatch]);

  return {
    list,
    one,
    getOne,
    clearOne,
    loading,
    getList,
    getMemberIdentifiers,
  };
};

export default useMembers;
