import React, { useEffect } from 'react';
// libs
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// store
import { setToken } from 'store/auth/actions';

const SuccessedLogin: React.FC = () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const token = searchParams.get('access_token');

    if (!token) {
      navigate('/login', { replace: true });
    }

    if (token) {
      dispatch(setToken({ access_token: token }));
      navigate('/team-members', { replace: true });
    }
  }, [dispatch, navigate, searchParams]);

  return <div>''</div>;
};

export default SuccessedLogin;
