import React, { useCallback, useMemo } from 'react';
// hooks
import useMembers from 'hooks/api/useMembers';
// types
import { FieldType, ServiceKey } from 'store/members/types';
import { Info, ServiceInfo } from 'pages/members/types';
// components
import Icon from 'components/icon';
import Field from '../../field';
import Avatar from '../../../../../../components/avatar';

export interface Params {
  serviceKey: ServiceKey;
}

const useInfo = ({ serviceKey }: Params) => {
  const { one } = useMembers();

  const memberInfo = useMemo(() => one[serviceKey] as Info, [one, serviceKey]);

  const infoData = useMemo(() => {
    if (memberInfo?.data) {
      return memberInfo;
    }

    return false;
  }, [memberInfo]);

  const getValueField = useCallback((type: FieldType, value: string) => {
    switch (type) {
      case 'Phone':
        return <Field size="large">{value}</Field>;
      case 'Date':
        return (
          <Field weight="regular" size="large" startIcon={<Icon icon="calendar" />}>
            {value}
          </Field>
        );
      case 'Email':
        return (
          <Field
            weight="bold"
            size="large"
            color="primary"
            startIcon={<Icon icon="envelope" />}
            endIcon={<Icon icon="arrowUpRight" />}
          >
            {value}
          </Field>
        );
      case 'Avatar':
        return <Avatar alt="user-avatar" src={value} big />;
      case 'Default':
      default:
        return (
          <Field weight="regular" size="large">
            {value}
          </Field>
        );
    }
  }, []);

  const getInfo = useCallback(
    (info: Info) => {
      return Object.entries(info.data).reduce((prevValue, [key, value]) => {
        if (value.type === 'hidden') {
          return [...prevValue];
        }

        const newField = {
          id: key,
          fieldName: <Field weight="medium">{key}</Field>,
          fieldData: getValueField(value.type, value.value),
          availableIn: value.syncStatuses,
          fieldOriginalName: key,
        };

        return [...prevValue, newField];
      }, [] as ServiceInfo);
    },
    [getValueField],
  );

  return { info: infoData ? getInfo(infoData) : [], memberInfo };
};

export default useInfo;
