import { FC } from 'react';
import Wrapper from 'containers/wrapper';

type Props = {
  title: string;
};

const Dummy: FC<Props> = ({ title }) => {
  return <Wrapper title={title} />;
};

export default Dummy;
