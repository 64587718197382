import React, { useCallback, useMemo } from 'react';
// libs
import { Field, FormikProvider, useFormik } from 'formik';
// types
import { ServiceFields } from 'hooks/api/useGetServiceFields';
import { Resource, ServiceKey, ServiceType } from 'store/members/types';
// hooks
import useMembers from 'hooks/api/useMembers';
import useRegisterMember from 'hooks/api/useRegisterMember';
import useProfile from 'hooks/api/useProfile';
// components
import Modal from 'components/modal';
import Button from 'components/button';
import Social from 'components/social';
import SelectField from 'components/formik/select';
// constants
import { SERVICE_TYPE_TO_TITLE } from 'constants/general';
// styles
import styles from './registerModal.module.css';

export interface RegisterModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceTitle: string;
  serviceFieldsData?: ServiceFields;
  serviceType?: ServiceType;
  serviceKey?: ServiceKey;
  serviceResource?: Resource;
}

export type SelectBoxValue = {
  value: { field: string; service: ServiceType; resource: Resource; externalIdValue: string };
  label: string;
  icon: JSX.Element;
};

export type Values = {
  [index: string]: SelectBoxValue[];
};

const RegisterModal: React.FC<RegisterModalProps> = ({
  isOpen,
  onClose,
  serviceTitle,
  serviceType,
  serviceResource,
  serviceKey,
  serviceFieldsData,
}) => {
  const { getMemberIdentifiers } = useMembers();

  const { register, isLoading: isRegisterInProcess } = useRegisterMember();

  const selectboxesData = useMemo((): Values => {
    if (serviceFieldsData) {
      const personsIds = getMemberIdentifiers();

      return Object.entries(serviceFieldsData).reduce((prevValue, [fieldKey, value]) => {
        const options = value
          ?.map((item) => {
            const {
              from: { service, resource },
            } = item;

            const isServiceSameAsResource = service === resource;

            const serviceResourceKey = isServiceSameAsResource
              ? service
              : (`${service}${resource}`.replace(/\s/g, '') as ServiceKey);

            const label = isServiceSameAsResource ? SERVICE_TYPE_TO_TITLE[service as ServiceType] : resource;

            const icon = <Social icon={service} className={styles.socialIcon} />;

            const externalIdValue = personsIds[serviceResourceKey];

            return { value: { field: fieldKey, service, resource, externalIdValue }, label, icon };
          })
          .filter((item) => item.value.externalIdValue);
        return { ...prevValue, [fieldKey]: options };
      }, {});
    }

    return {};
  }, [getMemberIdentifiers, serviceFieldsData]);

  const initialFormikValues = useMemo(() => {
    return Object.entries(selectboxesData).reduce((prevValue, [key, value]) => {
      return { ...prevValue, [key]: value[0] || {} };
    }, {}) as { [index: string]: SelectBoxValue };
  }, [selectboxesData]);

  const formik = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (serviceType && serviceResource && serviceKey) {
        const fields = Object.values(values)
          .map((value) => value.value)
          .filter((value) => value);

        const data = { service: serviceType, resource: serviceResource, fields, serviceKey, serviceTitle };

        register(data);
      }
    },
  });

  const renderSelectBoxes = useCallback(
    (values: { [index: string]: SelectBoxValue }) => {
      return (
        <div className={styles.selectboxesContainer}>
          {Object.entries(selectboxesData).map(([key, value]) => (
            <div className={styles.selectboxContainer} key={key}>
              <div>{key}</div>
              <Field
                isDisabled={!value.length}
                component={SelectField}
                name={key}
                options={value}
                className={styles.selectbox}
                defaultValue={values[key]}
              />
            </div>
          ))}
        </div>
      );
    },
    [selectboxesData],
  );

  const isDataEmpty = useMemo(
    () => !Object.keys(selectboxesData)?.length || Object.values(selectboxesData).every((item) => !item?.length),
    [selectboxesData],
  );

  const { profile } = useProfile();
  const isPaidService: boolean = profile.paidServices.some((type) => type === serviceType);

  return (
    <FormikProvider value={formik}>
      <Modal open={isOpen} onClose={onClose}>
        <div className={styles.container}>
          <div className={styles.title}>Register user in {serviceTitle}</div>
          {isDataEmpty ? 'No data to get register fields from' : renderSelectBoxes(initialFormikValues)}
          {isPaidService && <div className={styles.buttonsContainer}>Warning: This service is paid</div>}
          <div className={styles.buttonsContainer}>
            <Button onClick={onClose} color="secondary" fullWidth>
              Cancel
            </Button>
            <Button onClick={formik.submitForm} isLoading={isRegisterInProcess} disabled={isDataEmpty} fullWidth>
              Register
            </Button>
          </div>
        </div>
      </Modal>
    </FormikProvider>
  );
};

export default RegisterModal;
