import React, { useCallback } from 'react';
// components
import Modal from 'components/modal';
import Button from 'components/button';
// styles
import styles from './restoreModal.module.css';

export interface RestoreModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceTitle: string;
  onRestore?: () => void;
}

const RestoreModal: React.FC<RestoreModalProps> = ({ isOpen, onClose, onRestore, serviceTitle }) => {
  const handleRestoreClick = useCallback(() => {
    if (onRestore) {
      onRestore();
    }
  }, [onRestore]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.title}>Restore user in {serviceTitle}</div>
        <div className={styles.description}>User data will be restored in {serviceTitle}.</div>
        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} color="secondary" fullWidth>
            Cancel
          </Button>
          <Button onClick={handleRestoreClick} fullWidth>
            Restore
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RestoreModal;
