import { createSelector } from 'reselect';
import { Store } from '../types';
import { UIState } from './types';

const exampleSelector = (store: Store) => store.ui;

export const hideSidebarSelector = createSelector(exampleSelector, (state: UIState) => state.hideSidebar);

export const isOAuthLinkLoadingSelector = createSelector(exampleSelector, (state: UIState) => state.isOAuthLoading);

export const isSyncFieldLoadingSelector = createSelector(exampleSelector, (state: UIState) => state.isSyncFieldLoading);

export const isSyncAllLoadingSelector = createSelector(exampleSelector, (state: UIState) => state.isSyncAllLoading);

export const isServiceFieldsLoadingSelector = createSelector(
  exampleSelector,
  (state: UIState) => state.isServiceFieldsLoading,
);

export const isServiceActionLoadingSelector = createSelector(
  exampleSelector,
  (state: UIState) => state.isServiceActionLoading,
);

export const slackChannelsMetaSelector = createSelector(exampleSelector, (state: UIState) => state.slackChannelsMeta);
