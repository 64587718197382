const Flag = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33301 17.5L3.33301 3.33333M3.33301 10.8333H9.49967C9.96638 10.8333 10.1997 10.8333 10.378 10.7425C10.5348 10.6626 10.6623 10.5351 10.7422 10.3783C10.833 10.2001 10.833 9.96671 10.833 9.5V3.83333C10.833 3.36662 10.833 3.13327 10.7422 2.95501C10.6623 2.79821 10.5348 2.67072 10.378 2.59083C10.1997 2.5 9.96639 2.5 9.49967 2.5H4.66634C4.19963 2.5 3.96628 2.5 3.78802 2.59083C3.63121 2.67072 3.50373 2.79821 3.42384 2.95501C3.33301 3.13327 3.33301 3.36662 3.33301 3.83333V10.8333ZM10.833 4.16667H16.1663C16.6331 4.16667 16.8664 4.16667 17.0447 4.25749C17.2015 4.33739 17.329 4.46487 17.4088 4.62167C17.4997 4.79993 17.4997 5.03329 17.4997 5.5V11.1667C17.4997 11.6334 17.4997 11.8667 17.4088 12.045C17.329 12.2018 17.2015 12.3293 17.0447 12.4092C16.8664 12.5 16.6331 12.5 16.1663 12.5H12.1663C11.6996 12.5 11.4663 12.5 11.288 12.4092C11.1312 12.3293 11.0037 12.2018 10.9238 12.045C10.833 11.8667 10.833 11.6334 10.833 11.1667V4.16667Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Flag;
