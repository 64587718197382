import React from 'react';
// libs
import cx from 'classnames';
// types
import { ServiceIntegrationInfo } from 'pages/members/types';
// components
import Title from 'components/pageTitle';
// styles
import styles from '../../../services.module.css';

export interface IntegrationSectionProps {
  integrationInfo: ServiceIntegrationInfo;
  serviceTitle: string;
  description?: string;
}

const IntegrationSection: React.FC<IntegrationSectionProps> = ({ integrationInfo, serviceTitle, description }) => {
  return (
    <div>
      <Title
        className={cx(styles.title, { [styles.withMargin]: !description })}
      >{`${serviceTitle} integrations`}</Title>
      {description ? <div className={styles.description}>{description}</div> : null}
      <div className={styles.integrationsTable}>
        {integrationInfo.map(({ id, name, action }) => (
          <React.Fragment key={id}>
            <div className={styles.integrationsTableItem}>{name}</div>
            <div className={styles.integrationsTableItem}>{action}</div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default IntegrationSection;
