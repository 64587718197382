import React from 'react';
// libs
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
// store
import { tokenSelector } from 'store/auth/selector';

function UnauthorizedRoute() {
  const accessToken = useSelector(tokenSelector);

  if (!accessToken) {
    return <Outlet />;
  }

  return <Navigate to="/members" replace />;
}

export default UnauthorizedRoute;
