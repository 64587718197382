import { useCallback } from 'react';
// libs
import qs from 'qs';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// hooks
import useApi, { MemberActionsData } from './useApi';
import useMembers from './useMembers';
// store
import { ServiceKey } from '../../store/members/types';
import { isServiceActionLoadingSelector } from '../../store/ui/selector';
import { setIsServiceActionLoading } from '../../store/ui/actions';

const useServiceActions = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isServiceActionLoadingSelector);

  const { archiveMember, unArchiveMember, removeMember, restoreMember } = useApi();

  const { getOne } = useMembers();

  const navigate = useNavigate();

  const location = useLocation();

  const archive = useCallback(
    (data: MemberActionsData) => {
      dispatch(setIsServiceActionLoading(true));

      archiveMember(data)
        .then(() => {
          getOne(location.search);
          toast(`User is successfully archived`, { type: 'success' });
        })
        .catch((error) => toast(error, { type: 'error' }))
        .finally(() => dispatch(setIsServiceActionLoading(false)));
    },
    [dispatch, archiveMember, getOne, location.search],
  );

  const unArchive = useCallback(
    (data: MemberActionsData) => {
      dispatch(setIsServiceActionLoading(true));

      unArchiveMember(data)
        .then(() => {
          getOne(location.search);
          toast(`User is successfully unarchived`, { type: 'success' });
        })
        .catch((error) => toast(error, { type: 'error' }))
        .finally(() => dispatch(setIsServiceActionLoading(false)));
    },
    [dispatch, getOne, location.search, unArchiveMember],
  );

  const remove = useCallback(
    (data: MemberActionsData & { serviceKey: ServiceKey }) => {
      dispatch(setIsServiceActionLoading(true));
      const { serviceKey, ...rest } = data;

      removeMember(rest)
        .then(() => {
          const searchQuery = location.search.replace(/\?/g, '');
          const parsedQuery = qs.parse(searchQuery);

          const searchParams = Object.entries(parsedQuery).reduce((prevValue, [key, value]) => {
            if (key === serviceKey) {
              return { ...prevValue };
            }
            return { ...prevValue, [key]: value };
          }, {});

          const query = `?${qs.stringify(searchParams, { encode: false })}`;

          navigate(`${location.pathname}${query}`, { replace: true });

          getOne(query);

          toast(`User is successfully removed`, { type: 'success' });
        })
        .catch((error) => toast(error, { type: 'error' }))
        .finally(() => dispatch(setIsServiceActionLoading(false)));
    },
    [dispatch, getOne, location.pathname, location.search, navigate, removeMember],
  );

  const restore = useCallback(
    (data: MemberActionsData) => {
      dispatch(setIsServiceActionLoading(true));

      restoreMember(data)
        .then(() => {
          navigate('/team-members', { replace: true });
          toast(`User is successfully restored`, { type: 'success' });
        })
        .catch((error) => toast(error, { type: 'error' }))
        .finally(() => dispatch(setIsServiceActionLoading(false)));
    },
    [dispatch, navigate, restoreMember],
  );

  return { isLoading, archive, unArchive, remove, restore };
};

export default useServiceActions;
