import { Member, ServiceKey } from 'store/members/types';

export const generateMemberQuery = (member: Member, ignoredServices?: ServiceKey[]): string => {
  const keys: ServiceKey[] = Object.keys(member.services) as ServiceKey[];

  return keys
    .reduce((result: string[], key: ServiceKey) => {
      const serviceName = key as string;

      if (ignoredServices?.includes(key)) {
        return [...result];
      }

      return [
        ...result,
        ...(member.services[key].externalIdValue ? [`${serviceName}=${member.services[key].externalIdValue}`] : []),
      ];
    }, [])
    .join('&');
};
