const FlagUa = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4744_394460)">
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="#FFDA44"
      />
      <path d="M0 8C0 3.58175 3.58175 0 8 0C12.4183 0 16 3.58175 16 8" fill="#338AF3" />
    </g>
    <defs>
      <clipPath id="clip0_4744_394460">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FlagUa;
