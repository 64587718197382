import React, { useCallback, useState } from 'react';
// types
import { ServiceFields } from 'hooks/api/useGetServiceFields';
import { ServiceToSync } from '../../../../types';
// components
import SyncAllModal from '../../modals/syncAllModal';
import DeleteModal from '../../modals/deleteModal';
import SyncModal from '../../modals/syncModal';
import OAuthModal from '../../modals/oAuthModal';
import RegisterModal from '../../modals/registerModal';
import RestoreModal from '../../modals/restoreModal';
// store
import { Resource, ServiceKey, ServiceType } from '../../../../../../store/members/types';

export interface Params {
  serviceTitle: string;
  serviceType?: ServiceType;
  serviceResource?: Resource;
  serviceKey?: ServiceKey;
}

export interface RenderModalsParams {
  registerServiceFieldsData?: ServiceFields;
  syncService?: ServiceToSync;
  onOAuthProceed?: () => void;
  onSyncConfirm?: (serviceToSync: ServiceToSync) => void;
  onSyncAllConfirm?: () => void;
  onDeleteConfirm?: () => void;
  onRestoreConfirm?: () => void;
}

// TODO: change state to useReducer for better readability and for less code
const useModals = ({ serviceTitle, serviceType, serviceResource, serviceKey }: Params) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSyncAllModalOpen, setIsSyncAllModalOpen] = useState(false);
  const [isOAuthModalOpen, setIsOAuthModalOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false);

  const handleSyncAllModalOpen = useCallback(() => setIsSyncAllModalOpen(true), []);
  const handleSyncAllModalClose = useCallback(() => setIsSyncAllModalOpen(false), []);

  const handleOAuthModalOpen = useCallback(() => setIsOAuthModalOpen(true), []);
  const handleOAuthModalClose = useCallback(() => setIsOAuthModalOpen(false), []);

  const handleDeleteModalOpen = useCallback(() => setIsDeleteModalOpen(true), []);
  const handleDeleteModalClose = useCallback(() => setIsDeleteModalOpen(false), []);

  const handleRegisterModalOpen = useCallback(() => setIsRegisterModalOpen(true), []);
  const handleRegisterModalClose = useCallback(() => setIsRegisterModalOpen(false), []);

  const handleRestoreModalOpen = useCallback(() => setIsRestoreModalOpen(true), []);
  const handleRestoreModalClose = useCallback(() => setIsRestoreModalOpen(false), []);

  const [isSyncModalOpen, setIsSyncModalOpen] = useState(false);

  const handleSyncModalOpen = useCallback(() => setIsSyncModalOpen(true), []);

  const handleSyncModalClose = useCallback(() => setIsSyncModalOpen(false), []);

  const renderModals = useCallback(
    ({
      onOAuthProceed,
      onSyncConfirm,
      onSyncAllConfirm,
      syncService,
      registerServiceFieldsData,
      onDeleteConfirm,
      onRestoreConfirm,
    }: RenderModalsParams) => (
      <>
        <OAuthModal
          isOpen={isOAuthModalOpen}
          onClose={handleOAuthModalClose}
          serviceName={serviceTitle}
          onOAuthProceedClick={onOAuthProceed}
        />
        <SyncAllModal
          isOpen={isSyncAllModalOpen}
          onClose={handleSyncAllModalClose}
          serviceName={serviceTitle}
          onConfirmClick={onSyncAllConfirm}
        />
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={handleDeleteModalClose}
          serviceName={serviceTitle}
          onDelete={onDeleteConfirm}
        />
        <SyncModal
          isOpen={isSyncModalOpen}
          onClose={handleSyncModalClose}
          serviceTitle={serviceTitle}
          onConfirmClick={onSyncConfirm}
          serviceToSync={syncService}
        />
        <RegisterModal
          isOpen={isRegisterModalOpen}
          onClose={handleRegisterModalClose}
          serviceTitle={serviceTitle}
          serviceType={serviceType}
          serviceResource={serviceResource}
          serviceKey={serviceKey}
          serviceFieldsData={registerServiceFieldsData}
        />
        <RestoreModal
          isOpen={isRestoreModalOpen}
          onClose={handleRestoreModalClose}
          serviceTitle={serviceTitle}
          onRestore={onRestoreConfirm}
        />
      </>
    ),
    [
      handleRestoreModalClose,
      handleDeleteModalClose,
      handleOAuthModalClose,
      handleRegisterModalClose,
      handleSyncAllModalClose,
      handleSyncModalClose,
      isRestoreModalOpen,
      isDeleteModalOpen,
      isOAuthModalOpen,
      isRegisterModalOpen,
      isSyncAllModalOpen,
      isSyncModalOpen,
      serviceKey,
      serviceResource,
      serviceTitle,
      serviceType,
    ],
  );

  return {
    renderModals,
    handleSyncModalClose,
    handleSyncModalOpen,
    handleDeleteModalClose,
    handleDeleteModalOpen,
    handleSyncAllModalClose,
    handleSyncAllModalOpen,
    handleOAuthModalOpen,
    handleOAuthModalClose,
    handleRegisterModalOpen,
    handleRegisterModalClose,
    handleRestoreModalOpen,
    handleRestoreModalClose,
    isSyncModalOpen,
    isSyncAllModalOpen,
    isDeleteModalOpen,
    isRestoreModalOpen,
  };
};

export default useModals;
