import I from 'seamless-immutable';
// types
import {
  ApiCommonServiceData,
  ApiMember,
  ApiShowMember,
  Avatar,
  Avatars,
  CommonServiceData,
  EmptyService,
  ImageSize,
  Member,
  MembersState,
  Service,
  ServiceKey,
  ServiceType,
  ShowMember,
} from './types';

export const serviceAdapter = (data?: Service) => {
  if (data) {
    return I({
      status: data.status ?? 'not registered',
      externalIdName: data.externalIdName || '',
      service: data.service,
      resource: data.resource || '',
      externalIdValue: data.externalIdValue || '',
    });
  }
  return I({
    status: 'not registered',
  });
};

export const memberServicesAdapter = (data?: Record<ServiceKey, Service>) => {
  if (data) {
    return Object.entries(data).reduce((prevValue, [key, value]) => {
      return { ...prevValue, [key]: serviceAdapter(value) };
    }, {});
  }
  return {};
};

export const avatarAdapter = (data?: Avatars): Avatar => {
  const defaultValue = {
    image_original: '',
    image_24: '',
    image_32: '',
    image_48: '',
    image_72: '',
    image_192: '',
    image_512: '',
    image_1024: '',
  };
  if (data) {
    return data.reduce(
      (result: Avatar, item: Record<ImageSize, string>) => ({
        ...result,
        ...item,
      }),
      defaultValue,
    );
  }
  return defaultValue;
};

export const memberAdapter = (data?: ApiMember): Member => {
  if (data) {
    return I({
      name: data.name ?? 'Unknown Bro',
      email: data.email ?? '',
      avatar: avatarAdapter(data.avatars),
      services: memberServicesAdapter(data.pages),
    } as Member);
  }

  return I({
    name: 'Unknown Bro',
    email: 'email@example.com',
    avatar: avatarAdapter([]),
    services: memberServicesAdapter(),
  } as Member);
};

export const membersAdapter = (data: ApiMember[]) => {
  return I(data.map(memberAdapter));
};

export const emptyService = (service: ServiceType): EmptyService => {
  return {
    service,
    name: 'Unknown Bro',
    avatar: avatarAdapter(),
    status: 'not registered',
    email: '',
    externalIdValue: '',
    externalIdName: '',
    resource: '',
    data: undefined,
  };
};

export const oneServiceAdapter = (data?: ApiCommonServiceData): CommonServiceData | Record<string, never> => {
  if (data) {
    return {
      service: data.service,
      resource: data.resource,
      name: data.name,
      status: data.status ?? 'not registered',
      email: data.email,
      avatar: avatarAdapter(data.avatars),
      externalIdName: data.externalIdName,
      externalIdValue: data.externalIdValue,
      data: data.data ?? {},
    };
  }

  return {};
};

export const oneMemberAdapter = (data?: ApiShowMember): ShowMember => {
  if (data) {
    return Object.entries(data).reduce((prevValue, [key, value]) => {
      return { ...prevValue, [key]: oneServiceAdapter(value) };
    }, {});
  }

  return {};
};

export const membersStateAdapter = (): MembersState =>
  I({
    list: [],
    one: oneMemberAdapter(),
    isLoading: false,
  });
