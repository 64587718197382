import React, { useMemo } from 'react';
// providers
import useProfile from 'hooks/api/useProfile';
// store
import { ServiceKey } from 'store/members/types';
// components
import Social, { Icons } from 'components/social';
import Filter from './filter';
// styles
import styles from './membersTableFilters.module.css';

const MembersTableFilters: React.FC = () => {
  const { profile } = useProfile();

  const unauthenticatedServices = useMemo(() => {
    if (profile?.serviceAuth) {
      return Object.entries(profile?.serviceAuth)
        .map(([key, value]) => (value ? null : key))
        .filter((service) => service !== null);
    }
    return [];
  }, [profile?.serviceAuth]);

  const filterItems = useMemo(() => {
    if (profile?.showServices) {
      const services = Array.from(profile.showServices);

      const items = services
        .filter(({ serviceType }) => !unauthenticatedServices?.includes(serviceType as string))
        .reduce((prevValue, { serviceKey, serviceTitle, serviceType }) => {
          if (serviceKey && serviceTitle) {
            return [
              ...prevValue,
              {
                id: serviceKey,
                label: serviceTitle,
                value: { service: serviceKey, icon: <Social className={styles.icon} icon={serviceType as Icons} /> },
              },
            ];
          }
          return prevValue;
        }, [] as { id: string; label: string; value: { service: ServiceKey; icon: JSX.Element } }[]);

      return items;
    }

    return [];
  }, [profile.showServices, unauthenticatedServices]);

  return (
    <div className={styles.wrapper}>
      <Filter items={filterItems} />
    </div>
  );
};

export default MembersTableFilters;
