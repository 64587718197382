import { Action } from 'store/types';
import { AuthState } from './types';
import { authDefaultStateAdapter, authStateAdapter } from './adapters';
import { setToken, clearToken, setIsLoading } from './actions';

const initialStore = authDefaultStateAdapter();

const reducer = (state: AuthState = initialStore, action: Action) => {
  switch (action.type) {
    case setToken.type: {
      const token = authStateAdapter(action.payload);
      localStorage.setItem('token', token);
      return state.set('token', token);
    }

    case clearToken.type: {
      localStorage.removeItem('token');
      return state.set('token', '');
    }

    case setIsLoading.type: {
      return state.set('isLoading', action.payload);
    }

    default: {
      return state;
    }
  }
};

export default reducer;
