import React, { useMemo } from 'react';
// components
import Button from 'components/button';
import Field from '../../../field';
// styles
import styles from '../../services.module.css';

export interface Params {
  onDeleteButtonClick: () => void;
  onRegisterButtonClick: () => void;
  isRegisterButtonDisabled?: boolean;
  isDeleteButtonDisabled?: boolean;
}

const useJiraIntegration = ({
  onDeleteButtonClick,
  onRegisterButtonClick,
  isRegisterButtonDisabled,
  isDeleteButtonDisabled,
}: Params) => {
  const integrationInfo = useMemo(
    () => [
      {
        id: '1',
        name: (
          <Field className={styles.integrationsTableItemField} weight="medium">
            Register / Delete
          </Field>
        ),
        action: (
          <div style={{ display: 'flex', gap: 16 }}>
            <Button onClick={onRegisterButtonClick} disabled={isRegisterButtonDisabled}>
              Register
            </Button>
            <Button color="secondary" onClick={onDeleteButtonClick} disabled={isDeleteButtonDisabled}>
              Delete
            </Button>
          </div>
        ),
      },
    ],
    [isDeleteButtonDisabled, isRegisterButtonDisabled, onDeleteButtonClick, onRegisterButtonClick],
  );

  return { integrationInfo };
};

export default useJiraIntegration;
