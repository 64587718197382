export enum FilterStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  NOT_REGISTERED = 'not registered',
}

export enum FilterType {
  ALL = 0,
  ANY = 1,
}
