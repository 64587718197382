import React, { FunctionComponent } from 'react';
import cx from 'classnames';
import Icon from 'components/icon';
import styles from './spinner.module.css';

type Props = {
  className?: string;
  text?: string;
};

const Spinner: FunctionComponent<Props> = ({ className, text }) => {
  return (
    <div className={cx(styles.wrapper, className)}>
      <div className={styles.overlay} />
      <Icon icon="spinner" className={styles.icon} />
      {text ? <h4 className={styles.text}>{text}</h4> : null}
    </div>
  );
};

export default Spinner;
