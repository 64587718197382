import React from 'react';
// types
import { Resource, ServiceKey, ServiceType } from 'store/members/types';
// hooks
import useService from '../hooks/useService';
import useGoogleSheetsIntegration from '../hooks/integrations/useGoogleSheetsIntegration';
// components
import ServiceInfo from '../common/serviceInfo';
import Button from '../../../../../../components/button';
// styles
import styles from '../services.module.css';

export interface GoogleSheetsEmployeesInfoProps {
  serviceResource: Resource;
  serviceTitle: string;
  serviceKey: ServiceKey;
  serviceType: ServiceType;
}

const GoogleSheets: React.FC<GoogleSheetsEmployeesInfoProps> = ({
  serviceResource,
  serviceKey,
  serviceTitle,
  serviceType,
}) => {
  const {
    shouldAuth,
    info,
    handleSyncFieldClick,
    handleSyncAllModalOpen,
    renderModals,
    selectedSyncService,
    handleSyncFieldConfirm,
    handleSyncAllConfirm,
    serviceFieldsData,
    handleDeleteModalOpen,
    handleRegisterModalOpen,
    handleAuthenticateClick,
    isOAuthLinkLoading,
    memberInfo,
    handleArchiveConfirm,
    handleUnArchiveConfirm,
  } = useService({ serviceType, serviceResource, serviceTitle, serviceKey });

  const { integrationInfo } = useGoogleSheetsIntegration({
    onDeleteButtonClick: handleDeleteModalOpen,
    onRegisterButtonClick: handleRegisterModalOpen,
    isRegisterButtonDisabled: !!info.length,
    onArchiveButtonClick: handleArchiveConfirm,
    onUnArchiveButtonClick: handleUnArchiveConfirm,
    isDeleteButtonDisabled: !info.length,
    isArchiveToggleDisabled: !info.length,
    isArchived: memberInfo?.status === 'inactive',
  });

  return (
    <div className={styles.wrapper}>
      {!shouldAuth ? (
        <ServiceInfo
          serviceTitle={serviceTitle}
          info={info}
          integrationInfo={integrationInfo}
          onServiceClick={handleSyncFieldClick}
          onSyncAllClick={handleSyncAllModalOpen}
        />
      ) : (
        <Button onClick={handleAuthenticateClick} isLoading={isOAuthLinkLoading}>
          Authenticate
        </Button>
      )}
      {renderModals({
        syncService: selectedSyncService,
        onSyncConfirm: handleSyncFieldConfirm,
        onSyncAllConfirm: handleSyncAllConfirm,
        registerServiceFieldsData: serviceFieldsData,
      })}
    </div>
  );
};

export default GoogleSheets;
