import { useCallback } from 'react';
// libs
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
// types
import { AxiosResponse } from 'axios';
// hooks
import useApi, { AddUserToSlackParams, RemoveUserFromSlackParams } from './useApi';
import useMembers from './useMembers';
// store
import { setSlackChannelsMeta } from '../../store/ui/actions';
import { isServiceFieldsLoadingSelector, slackChannelsMetaSelector } from '../../store/ui/selector';

const useSlackChannels = () => {
  const slackChannelsMeta = useSelector(slackChannelsMetaSelector);

  const { getSlackChannels: getChannels, addUserToSlackChannel, deleteUserFromSlackChannel } = useApi();

  const { getOne } = useMembers();

  const location = useLocation();

  const dispatch = useDispatch();

  const isLoading = useSelector(isServiceFieldsLoadingSelector);

  const getSlackChannels = useCallback(
    async (slackId: string) => {
      dispatch(setSlackChannelsMeta({ ...slackChannelsMeta, isChannelsLoading: true }));

      const response: AxiosResponse = await getChannels(slackId)
        .catch((error) => error)
        .finally(() => dispatch(setSlackChannelsMeta({ ...slackChannelsMeta, isChannelsLoading: false })));

      return response.data;
    },
    [dispatch, getChannels, slackChannelsMeta],
  );

  const addToSlackChannel = useCallback(
    (data: AddUserToSlackParams) => {
      dispatch(setSlackChannelsMeta({ ...slackChannelsMeta, isAddingToChannelLoading: true }));

      addUserToSlackChannel(data)
        .then(() => toast('User is successfully added to channel', { type: 'success' }))
        .catch((error) => toast(`Error: ${error?.data?.message}` || 'Something went wrong...', { type: 'error' }))
        .finally(() => {
          getOne(location.search);
          dispatch(setSlackChannelsMeta({ ...slackChannelsMeta, isAddingToChannelLoading: false }));
        });
    },
    [addUserToSlackChannel, dispatch, getOne, location.search, slackChannelsMeta],
  );

  const removeFromSlackChannel = useCallback(
    (data: RemoveUserFromSlackParams) => {
      dispatch(setSlackChannelsMeta({ ...slackChannelsMeta, isRemoveFromChannelLoading: true }));

      deleteUserFromSlackChannel(data)
        .then(() => toast('User is successfully removed from channel', { type: 'success' }))
        .catch((error) => toast(`Error: ${error?.data?.message}` || 'Something went wrong...', { type: 'error' }))
        .finally(() => {
          getOne(location.search);
          dispatch(setSlackChannelsMeta({ ...slackChannelsMeta, isRemoveFromChannelLoading: false }));
        });
    },
    [deleteUserFromSlackChannel, dispatch, getOne, location.search, slackChannelsMeta],
  );

  return { isLoading, getSlackChannels, addToSlackChannel, removeFromSlackChannel, slackChannelsMeta };
};

export default useSlackChannels;
