import { useCallback } from 'react';
// libs
import { useDispatch, useSelector } from 'react-redux';
// types
import { AxiosResponse } from 'axios';
// store
import { setProfile, setIsLoading } from '../../store/profile/actions';
import { isLoadingSelector, profileSelector } from '../../store/profile/selector';
// hooks
import useApi from './useApi';

const useProfile = () => {
  const profile = useSelector(profileSelector);

  const { getProfile: getUserProfile } = useApi();

  const dispatch = useDispatch();

  const isLoading = useSelector(isLoadingSelector);

  const getProfile = useCallback(async () => {
    dispatch(setIsLoading(true));

    const response: AxiosResponse = await getUserProfile()
      .catch((error) => error)
      .finally(() => dispatch(setIsLoading(false)));

    dispatch(setProfile(response.data));
  }, [dispatch, getUserProfile]);

  return { getProfile, profile, isLoading };
};

export default useProfile;
