import React, { useMemo } from 'react';
import ReactSelect, { GroupBase, Props, StylesConfig } from 'react-select';

export type SelectOption = { value: any; label: string; icon?: JSX.Element };

// TODO: refactor select component, fix eslint warnings
function Select<
  Option = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group>) {
  const styles: StylesConfig<Option, IsMulti, Group> = useMemo(
    () => ({
      control: (styles, state) => ({
        ...styles,
        borderRadius: '8px',
        boxShadow: 'none',
        borderColor: state.isFocused ? 'var(--primary-300)' : 'var(--gray-300)',
        '&:hover': {
          borderColor: state.isFocused ? 'var(--primary-300)' : 'var(--gray--300)',
        },
        outline: state.isFocused ? '4px solid var(--primary-100)' : 'none',
      }),
      menu: (styles) => ({
        ...styles,
        borderRadius: '8px',
      }),
      menuList: (styles) => ({
        ...styles,
        borderRadius: '8px',
      }),
      option: (styles, state) => ({
        ...styles,
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: state.isDisabled
          ? undefined
          : // eslint-disable-next-line no-nested-ternary
          state.isSelected
          ? 'var(--gray-50)'
          : state.isFocused
          ? 'var(--primary-50)'
          : undefined,
        color: 'var(--gray-900)',
        ':active': {
          // eslint-disable-next-line no-nested-ternary
          backgroundColor: !state.isDisabled
            ? state.isSelected
              ? 'var(--gray-200)'
              : 'var(--primary-200)'
            : undefined,
        },
      }),
    }),
    [],
  );

  return (
    <ReactSelect
      {...props}
      noOptionsMessage={({ inputValue }) => (!inputValue ? 'No options available' : 'No results found')}
      placeholder="Select an option"
      styles={styles}
      getOptionLabel={(data) => {
        const option = { ...data } as SelectOption;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ width: '20px', height: '20px', marginRight: '8px' }}>{option?.icon}</span>
            <span style={{ fontWeight: 500, fontSize: '16px', color: 'var(--gray-900)' }}>{option?.label}</span>
          </div>
        ) as any;
      }}
    />
  );
}

export default Select;
