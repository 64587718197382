import { Status } from '../store/members/types';

export const getEmptyService = (service?: string, resource?: string) => {
  return {
    service: service || '',
    resource: resource || '',
    name: 'Unknown Bro',
    avatar: {
      image_original: '',
      image_24: '',
      image_32: '',
      image_48: '',
      image_72: '',
      image_192: '',
      image_512: '',
      image_1024: '',
    },
    status: 'not registered' as Status,
    email: '',
    externalIdValue: '',
    externalIdName: '',
    data: undefined,
  };
};
