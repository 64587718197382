import { useCallback } from 'react';
// libs
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// store
import { setIsSyncAllLoading } from '../../store/ui/actions';
import { isSyncAllLoadingSelector } from '../../store/ui/selector';
// hooks
import useApi, { SyncAllParams } from './useApi';
import useMembers from './useMembers';

const useSyncAll = () => {
  const { search } = useLocation();

  const dispatch = useDispatch();

  const { syncAll: synchronizeAll } = useApi();

  const { getOne } = useMembers();

  const isLoading = useSelector(isSyncAllLoadingSelector);

  const syncAll = useCallback(
    (data: SyncAllParams) => {
      dispatch(setIsSyncAllLoading(true));

      synchronizeAll(data)
        .then(() => {
          toast(`All data is successfully synchronized`, { type: 'success' });
        })
        .catch((error) => toast(error, { type: 'error' }))
        .finally(() => {
          getOne(search);
          dispatch(setIsSyncAllLoading(false));
        });
    },
    [dispatch, getOne, search, synchronizeAll],
  );

  return { syncAll, isLoading };
};

export default useSyncAll;
