import React, { useCallback, useState } from 'react';
// libs
import cx from 'classnames';
// types
import { Status } from 'store/members/types';
// components
import StatusBadge from 'containers/status';
// providers
import { useMembersTableFiltersContext } from 'pages/members/components/membersTableFilters/providers/membersTableFiltersProvider';
// components
import PopoverMenu from 'components/popover';
import Menu, { Item } from 'pages/members/components/membersTableFilters/filter/menu';
import FilterTypeComponent from 'pages/members/components/membersTableFilters/filterType';
// icons
import { ReactComponent as AddIcon } from 'assets/add.svg';
import { ReactComponent as CrossIcon } from 'assets/cross-sm.svg';
// constants
import { FilterStatus, FilterType } from '../constants';
// styles
import styles from './filter.module.css';

export interface FilterMenuProps {
  items: Item[];
}

const FILTER_TYPE_ITEMS = [
  { label: 'Match all filters', value: FilterType.ALL },
  { label: 'Match any filters', value: FilterType.ANY },
];

const STATUS_FILTER_ITEMS = [
  {
    id: FilterStatus.NOT_REGISTERED,
    label: (
      <div>
        Status:
        <StatusBadge className={styles.itemStatusBadge} status="not registered" />
      </div>
    ),
    value: FilterStatus.NOT_REGISTERED,
  },
  {
    id: FilterStatus.INACTIVE,
    label: (
      <div>
        Status:
        <StatusBadge className={styles.itemStatusBadge} status="inactive" />
      </div>
    ),
    value: FilterStatus.INACTIVE,
  },
  {
    id: FilterStatus.ACTIVE,
    label: (
      <div>
        Status:
        <StatusBadge className={styles.itemStatusBadge} status="active" />
      </div>
    ),
    value: FilterStatus.ACTIVE,
  },
];

// TODO: refactor this and FilterType component
const Filter: React.FC<FilterMenuProps> = ({ items }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isStatusesMenuOpened, setIsStatusesMenuOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const { selectedFilters, setSelectedFilters } = useMembersTableFiltersContext();

  const handleIsMenuOpenedChange = useCallback((isOpen: boolean) => setIsMenuOpened(isOpen), []);

  const handleIsStatusesMenuChange = useCallback((isOpen: boolean) => setIsStatusesMenuOpened(isOpen), []);

  const handleMenuItemClick = useCallback((item: Item) => {
    setSelectedItem(item);
    setIsMenuOpened(false);
    setIsStatusesMenuOpened(true);
  }, []);

  const handleStatusesMenuItemClick = useCallback(
    (item: Item) => {
      if (selectedItem) {
        const status: Status = item.value ?? 'not registered';
        const enhancedSelectedItem = {
          ...selectedItem,
          value: {
            ...selectedItem.value,
            status,
            statusLabel: <StatusBadge className={styles.statusBadge} status={status} />,
          },
        };
        const items = [...selectedFilters, enhancedSelectedItem];
        setSelectedFilters(items);
        setSelectedItem(null);
        setIsStatusesMenuOpened(false);
      }
    },
    [selectedItem, selectedFilters, setSelectedFilters],
  );

  const handleCloseTagButtonClick = useCallback(
    (id: string) => () => {
      const items = selectedFilters.filter((selectedItem) => selectedItem.id !== id);
      setSelectedFilters(items);
    },
    [selectedFilters, setSelectedFilters],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.tagsWrapper}>
        <FilterTypeComponent items={FILTER_TYPE_ITEMS} />
        {selectedFilters.map((selectedItem) => {
          return (
            <div className={styles.tag} key={selectedItem.id}>
              {selectedItem?.value?.icon}
              {`${selectedItem.label}:`}
              {selectedItem?.value?.statusLabel}
              <button
                type="button"
                className={styles.closeTagIcon}
                onClick={handleCloseTagButtonClick(selectedItem.id)}
              >
                <CrossIcon />
              </button>
            </div>
          );
        })}
        <PopoverMenu
          isOpen={isMenuOpened}
          setIsOpen={handleIsMenuOpenedChange}
          button={
            <button className={cx(styles.menuButton)} type="button">
              <AddIcon width={12} height={12} />
              Add Filter
            </button>
          }
          content={
            <Menu items={items} selectedItems={selectedFilters} onMenuItemClick={handleMenuItemClick} showSearchInput />
          }
        />
        <PopoverMenu
          className={styles.statusesMenu}
          isOpen={isStatusesMenuOpened}
          setIsOpen={handleIsStatusesMenuChange}
          content={
            <Menu items={STATUS_FILTER_ITEMS} selectedItems={[]} onMenuItemClick={handleStatusesMenuItemClick} />
          }
        />
      </div>
    </div>
  );
};

export default Filter;
