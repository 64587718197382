import { useCallback, useState } from 'react';
// libs
import qs from 'qs';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
// hooks
import useApi, { RegisterMemberData } from './useApi';
import useMembers from './useMembers';
// store
import { ServiceKey } from '../../store/members/types';

export type RegisterData = RegisterMemberData & { serviceKey?: ServiceKey; serviceTitle?: string };

const useRegisterMember = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { registerMember } = useApi();

  const { getOne } = useMembers();

  const navigate = useNavigate();

  const location = useLocation();

  const register = useCallback(
    (data: RegisterData) => {
      setIsLoading(true);

      const { serviceKey, serviceTitle, ...rest } = data;

      registerMember(rest)
        .then((response) => {
          const { data: responseData } = response;
          const { page, externalIdValue } = responseData;

          const service = page as ServiceKey;
          const externalId = externalIdValue as string;
          const searchQuery = location.search.replace(/\?/g, '');
          const searchParams = { ...qs.parse(searchQuery), [service]: externalId };
          const query = `?${qs.stringify(searchParams, { encode: false })}`;

          navigate(`${location.pathname}${query}`, { replace: true });

          getOne(query);

          toast(`User is successfully registered in ${serviceTitle}`, { type: 'success' });
        })
        .catch((error) => toast(error, { type: 'error' }))
        .finally(() => setIsLoading(false));
    },
    [getOne, location.pathname, location.search, navigate, registerMember],
  );

  return { isLoading, register };
};

export default useRegisterMember;
