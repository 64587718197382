import { Action } from 'store/types';
import { ExampleState } from './types';
import { exampleStateAdapter } from './adapters';
import { setFuuExampleAction } from './actions';

const initialStore = exampleStateAdapter();

const reducer = (state: ExampleState = initialStore, action: Action) => {
  switch (action.type) {
    case setFuuExampleAction.type: {
      return state.set('fuu', action.payload);
    }

    default: {
      return state;
    }
  }
};

export default reducer;
