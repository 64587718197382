const Envelope = () => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.6665 3.83334L8.4706 8.59621C9.02158 8.9819 9.29707 9.17474 9.59672 9.24943C9.86142 9.31541 10.1383 9.31541 10.403 9.24943C10.7026 9.17474 10.9781 8.9819 11.5291 8.59621L18.3332 3.83334M5.6665 14.6667H14.3332C15.7333 14.6667 16.4334 14.6667 16.9681 14.3942C17.4386 14.1545 17.821 13.7721 18.0607 13.3017C18.3332 12.7669 18.3332 12.0668 18.3332 10.6667V5.33334C18.3332 3.93321 18.3332 3.23315 18.0607 2.69837C17.821 2.22796 17.4386 1.84551 16.9681 1.60583C16.4334 1.33334 15.7333 1.33334 14.3332 1.33334H5.6665C4.26637 1.33334 3.56631 1.33334 3.03153 1.60583C2.56112 1.84551 2.17867 2.22796 1.93899 2.69837C1.6665 3.23315 1.6665 3.93321 1.6665 5.33334V10.6667C1.6665 12.0668 1.6665 12.7669 1.93899 13.3017C2.17867 13.7721 2.56112 14.1545 3.03153 14.3942C3.56631 14.6667 4.26637 14.6667 5.6665 14.6667Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Envelope;
