import { Action } from 'store/types';
import { ProfileState } from './types';
import { profileAdapter, profileDefaultStateAdapter } from './adapters';
import { setIsLoading, setProfile } from './actions';

const initialStore = profileDefaultStateAdapter();

const reducer = (state: ProfileState = initialStore, action: Action) => {
  switch (action.type) {
    case setProfile.type: {
      return state.set('profile', profileAdapter(action.payload));
    }

    case setIsLoading.type: {
      return state.set('isLoading', action.payload);
    }

    default: {
      return state;
    }
  }
};

export default reducer;
