import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
// libs
import cx from 'classnames';
// styles
import styles from './switch.module.css';

export interface SwitchProps {
  name?: string;
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Switch: React.FC<SwitchProps> = ({ name, className, checked, disabled, onChange }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsChecked(event.target.checked);
      if (onChange) {
        onChange(event);
      }
    },
    [onChange],
  );

  useEffect(() => {
    setIsChecked(checked ?? false);
  }, [checked]);

  return (
    <label className={cx(styles.switch, className)}>
      <input name={name} type="checkbox" checked={isChecked} onChange={handleChange} tabIndex={0} disabled={disabled} />
      <span className={cx(styles.slider, styles.round)} />
    </label>
  );
};

export default Switch;
