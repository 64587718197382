import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideSidebarAction, showSidebarAction } from '../store/ui/actions';
import { hideSidebarSelector } from '../store/ui/selector';

type IUseUI = {
  hideSidebar: boolean;
  onHideSidebar: () => void;
  onShowSidebar: () => void;
};

const useUI = (): IUseUI => {
  const dispatch = useDispatch();
  const hideSidebar = useSelector(hideSidebarSelector);

  const onHideSidebar = useCallback(() => dispatch(hideSidebarAction()), [dispatch]);
  const onShowSidebar = useCallback(() => dispatch(showSidebarAction()), [dispatch]);

  return {
    hideSidebar,
    onHideSidebar,
    onShowSidebar,
  };
};

export default useUI;
