import React, { ChangeEvent, FC } from 'react';
import cx from 'classnames';
import styles from './input.module.css';
import Icon, { Icons } from '../icon';

type Props = {
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  name: string;
  value?: string | number;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  icon?: Icons;
  type?: 'email' | 'text' | 'number' | 'password';
};

const Input: FC<Props> = (props: Props) => {
  const { className, onChange, icon, value, placeholder, name, disabled, type = 'text', inputClassName } = props;
  return (
    <div className={cx(styles.wrapper, className, { [styles.withIcon]: icon })}>
      <input
        type={type}
        className={cx(styles.input, inputClassName)}
        value={value}
        onChange={onChange}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
      />
      {icon ? <Icon icon={icon} className={styles.icon} /> : null}
    </div>
  );
};

export default Input;
