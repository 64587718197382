import React, { ChangeEvent, useCallback, useMemo } from 'react';
// components
import Button from 'components/button';
import Field from '../../../field';
import Switch from '../../../../../../../components/switch';
// styles
import styles from '../../services.module.css';

export interface Params {
  onDeleteButtonClick: () => void;
  onRegisterButtonClick: () => void;
  isRegisterButtonDisabled?: boolean;
  isDeleteButtonDisabled?: boolean;
  onArchiveButtonClick: () => void;
  onUnArchiveButtonClick: () => void;
  isArchiveToggleDisabled?: boolean;
  isArchived?: boolean;
}

const useGoogleSheetsIntegration = ({
  onDeleteButtonClick,
  onRegisterButtonClick,
  isRegisterButtonDisabled,
  isDeleteButtonDisabled,
  onArchiveButtonClick,
  onUnArchiveButtonClick,
  isArchiveToggleDisabled,
  isArchived,
}: Params) => {
  const handleArchiveChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        onArchiveButtonClick();
      }
      if (!event.target.checked) {
        onUnArchiveButtonClick();
      }
    },
    [onArchiveButtonClick, onUnArchiveButtonClick],
  );

  const integrationInfo = useMemo(
    () => [
      {
        id: '1',
        name: (
          <Field className={styles.integrationsTableItemField} weight="medium">
            Deactivate/Archive
          </Field>
        ),
        action: (
          <Switch checked={isArchived ?? false} disabled={isArchiveToggleDisabled} onChange={handleArchiveChange} />
        ),
      },
      {
        id: '2',
        name: (
          <Field className={styles.integrationsTableItemField} weight="medium">
            Register / Delete
          </Field>
        ),
        action: (
          <div style={{ display: 'flex', gap: 16 }}>
            <Button onClick={onRegisterButtonClick} disabled={isRegisterButtonDisabled}>
              Register
            </Button>
            <Button color="secondary" onClick={onDeleteButtonClick} disabled={isDeleteButtonDisabled}>
              Delete
            </Button>
          </div>
        ),
      },
    ],
    [
      handleArchiveChange,
      isArchiveToggleDisabled,
      isArchived,
      isDeleteButtonDisabled,
      isRegisterButtonDisabled,
      onDeleteButtonClick,
      onRegisterButtonClick,
    ],
  );

  return { integrationInfo };
};

export default useGoogleSheetsIntegration;
