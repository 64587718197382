import { useCallback, useEffect, useState } from 'react';
import useGetServiceFields, { ServiceFields } from 'hooks/api/useGetServiceFields';
import { Resource, ServiceType } from 'store/members/types';

export interface Params {
  serviceType: ServiceType;
  serviceResource: Resource;
  isRegistrable: boolean;
}

const useServiceFieldsData = ({ serviceType, serviceResource, isRegistrable }: Params) => {
  const [serviceFieldsData, setServiceFieldsData] = useState<ServiceFields>();

  const { getServiceFields } = useGetServiceFields();

  const getServiceData = useCallback(async () => {
    getServiceFields({ service: serviceType, resource: serviceResource })
      .then((data) => setServiceFieldsData(data))
      .catch(() => setServiceFieldsData(undefined));
  }, [getServiceFields, serviceResource, serviceType]);

  useEffect(() => {
    if (isRegistrable) {
      getServiceData();
    }
  }, [serviceResource, isRegistrable]);

  return { serviceFieldsData };
};

export default useServiceFieldsData;
