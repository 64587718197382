import React from 'react';
// libs
import cx from 'classnames';
// styles
import styles from './field.module.css';

export type FieldColor = 'primary' | 'secondary';
export type FieldWeight = 'regular' | 'medium' | 'bold';
export type FieldSize = 'normal' | 'large';

export interface FieldProps {
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  color?: FieldColor;
  weight?: FieldWeight;
  size?: FieldSize;
  className?: string;
  children: React.ReactNode;
}

const Field: React.FC<FieldProps> = ({
  startIcon,
  endIcon,
  children,
  className,
  color = 'secondary',
  size = 'normal',
  weight = 'regular',
}) => {
  return (
    <div className={cx(styles.container, className)}>
      {startIcon && children ? <span>{startIcon}</span> : null}
      <span className={cx(styles.field, styles[color], styles[size], styles[weight])}>{children || '-'}</span>
      {endIcon && children ? <span>{endIcon}</span> : null}
    </div>
  );
};

export default Field;
