import { createSelector } from 'reselect';
import { Store } from '../types';
import { MembersState } from './types';

const membersStoreSelector = (store: Store) => store.members;

export const membersListSelector = createSelector(membersStoreSelector, (state: MembersState) =>
  state.list.asMutable({ deep: true }),
);

export const oneMemberSelector = createSelector(membersStoreSelector, (state: MembersState) =>
  state.one.asMutable({ deep: true }),
);

export const isLoadingSelector = createSelector(membersStoreSelector, (state: MembersState) => state.isLoading);
