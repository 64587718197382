import React, { FC, ReactElement } from 'react';
import cx from 'classnames';
import styles from './title.module.css';

type Props = {
  children: string | ReactElement;
  className?: string;
};

const Title: FC<Props> = ({ children, className }) => {
  return <h1 className={cx(styles.title, className)}>{children}</h1>;
};

export default Title;
