import React from 'react';
// types
import { Resource, ServiceKey, ServiceType } from 'store/members/types';
// components
import Button from 'components/button';
import ServiceInfo from '../common/serviceInfo';
// hooks
import useService from '../hooks/useService';
import useSlackIntegration from '../hooks/integrations/useSlackIntegration';
// styles
import styles from '../services.module.css';

export interface SlackInfoProps {
  serviceResource: Resource;
  serviceTitle: string;
  serviceKey: ServiceKey;
  serviceType: ServiceType;
}

const Slack: React.FC<SlackInfoProps> = ({ serviceResource, serviceTitle, serviceKey, serviceType }) => {
  const {
    shouldAuth,
    info,
    handleSyncFieldClick,
    handleSyncAllModalOpen,
    isOAuthLinkLoading,
    handleAuthenticateClick,
    renderModals,
    selectedSyncService,
    handleSyncFieldConfirm,
    handleSyncAllConfirm,
    handleOAuthProceedClick,
    serviceFieldsData,
    handleDeleteModalOpen,
    handleRegisterModalOpen,
    serviceExternalId,
  } = useService({ serviceType, serviceResource, serviceTitle, serviceKey });

  const { integrationInfo } = useSlackIntegration({
    onDeleteButtonClick: handleDeleteModalOpen,
    onRegisterButtonClick: handleRegisterModalOpen,
    isRegisterButtonDisabled: true,
    isDeleteButtonDisabled: true,
    serviceExternalId,
  });

  return (
    <div className={styles.wrapper}>
      {!shouldAuth ? (
        <ServiceInfo
          serviceTitle={serviceTitle}
          info={info}
          integrationInfo={integrationInfo}
          onServiceClick={handleSyncFieldClick}
          onSyncAllClick={handleSyncAllModalOpen}
        />
      ) : (
        <Button onClick={handleAuthenticateClick} isLoading={isOAuthLinkLoading}>
          Authenticate
        </Button>
      )}
      {renderModals({
        syncService: selectedSyncService,
        onSyncConfirm: handleSyncFieldConfirm,
        onSyncAllConfirm: handleSyncAllConfirm,
        onOAuthProceed: handleOAuthProceedClick,
        registerServiceFieldsData: serviceFieldsData,
      })}
    </div>
  );
};

export default Slack;
