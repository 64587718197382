const Help = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4747_395352)">
      <path
        d="M6.05967 6.00001C6.21641 5.55446 6.52578 5.17875 6.93298 4.93943C7.34018 4.70012 7.81894 4.61264 8.28446 4.69248C8.74998 4.77233 9.17222 5.01436 9.47639 5.3757C9.78057 5.73703 9.94705 6.19436 9.94634 6.66668C9.94634 8.00001 7.94634 8.66668 7.94634 8.66668M7.99967 11.3333H8.00634M14.6663 8.00001C14.6663 11.6819 11.6816 14.6667 7.99967 14.6667C4.31778 14.6667 1.33301 11.6819 1.33301 8.00001C1.33301 4.31811 4.31778 1.33334 7.99967 1.33334C11.6816 1.33334 14.6663 4.31811 14.6663 8.00001Z"
        stroke="#98A2B3"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4747_395352">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Help;
