import React from 'react';
// types
import { ServiceInfo as ServiceInfoType, ServiceIntegrationInfo, SyncFieldClickParams } from 'pages/members/types';
// components
import InfoSection from './infoSection';
import IntegrationSection from './integrationSection';
// styles
import styles from '../../services.module.css';

export interface ServiceInfoProps {
  info: ServiceInfoType;
  integrationInfo: ServiceIntegrationInfo;
  serviceTitle: string;
  onServiceClick?: (params: SyncFieldClickParams) => void;
  onSyncAllClick?: () => void;
}

const ServiceInfo: React.FC<ServiceInfoProps> = ({
  info,
  integrationInfo,
  serviceTitle,
  onServiceClick,
  onSyncAllClick,
}) => {
  return (
    <div className={styles.container}>
      {info.length ? (
        <InfoSection
          info={info}
          serviceTitle={serviceTitle}
          onServiceClick={onServiceClick}
          onSyncAllClick={onSyncAllClick}
        />
      ) : (
        <div>No info presented</div>
      )}
      {integrationInfo.length ? (
        <IntegrationSection integrationInfo={integrationInfo} serviceTitle={serviceTitle} />
      ) : (
        <div>No integrations available</div>
      )}
    </div>
  );
};

export default ServiceInfo;
