import React, { FC, ReactElement, useCallback, useState } from 'react';
import cx from 'classnames';
import Social, { Icons } from 'components/social';
import styles from './tabs.module.css';

export type Tab = {
  id: string;
  label: string;
  element?: ReactElement;
  icon: Icons;
};

type Props = {
  list: Tab[];
  onTabChange?: (activeTab: string) => void;
  activeTab?: string;
};

const Tabs: FC<Props> = ({ list, onTabChange, activeTab }) => {
  const [active, onChangeActive] = useState<string>(activeTab || list[0]?.id || '');

  const current = list.find((tab: Tab) => tab.id === active);

  const handleChangeActiveTab = useCallback(
    (id: string) => () => {
      onChangeActive(id);
      if (onTabChange) {
        onTabChange(id);
      }
    },
    [onTabChange],
  );

  return (
    <div className={styles.wrapper}>
      <ul>
        {list.map((tab: Tab) => (
          <li className={cx(styles.tab, { [styles.active]: active === tab.id })} key={tab.id}>
            <button
              className={cx(styles.tabButton, { [styles.active]: active === tab.id })}
              onClick={handleChangeActiveTab(tab.id)}
              type="button"
            >
              <Social icon={tab.icon} className={styles.icon} />
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
      <div className={styles.elementWrapper}>{current ? current.element : null}</div>
    </div>
  );
};

export default Tabs;
