import React, { useCallback } from 'react';
// components
import Modal from 'components/modal';
import Button from 'components/button';
// images
import avatarsGroup from 'assets/avatarsGroup.png';
// types
import { ServiceToSync } from '../../../../types';
// styles
import styles from './syncModal.module.css';

export interface SyncModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceTitle: string;
  serviceToSync?: ServiceToSync;
  onConfirmClick?: (serviceToSync: ServiceToSync) => void;
}

const SyncModal: React.FC<SyncModalProps> = ({ isOpen, onClose, serviceTitle, serviceToSync, onConfirmClick }) => {
  const handleSyncConfirmClick = useCallback(() => {
    const { key, resource, field, title, type } = serviceToSync ?? {};

    if (onConfirmClick && key && resource && field && title && type) {
      onConfirmClick({ key, field, resource, title, type });
    }
  }, [onConfirmClick, serviceToSync]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <img className={styles.image} src={avatarsGroup} alt="avatars-group" />
        <div className={styles.title}>
          Sync {serviceTitle} data with {serviceToSync?.title} data
        </div>
        <div className={styles.description}>
          As a result of synchronization, data on {serviceToSync?.title} will be replaced with data from the{' '}
          {serviceTitle} service
        </div>
        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} color="secondary" fullWidth>
            Cancel
          </Button>
          <Button onClick={handleSyncConfirmClick} fullWidth>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SyncModal;
