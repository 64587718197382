export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0002 3.33329C6.31826 3.33329 3.3335 6.31806 3.3335 9.99996C3.3335 13.6819 6.31826 16.6666 10.0002 16.6666C13.6821 16.6666 16.6668 13.6819 16.6668 9.99996"
      stroke="#B71556"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
);
