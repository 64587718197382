import React, { useCallback } from 'react';
// components
import Modal from 'components/modal';
import Button from 'components/button';
// styles
import styles from './oAuthModal.module.css';

export interface OAuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceName: string;
  onOAuthProceedClick?: () => void;
}

const OAuthModal: React.FC<OAuthModalProps> = ({ isOpen, onClose, serviceName, onOAuthProceedClick }) => {
  const handleOAuthProceedClick = useCallback(() => {
    if (onOAuthProceedClick) {
      onOAuthProceedClick();
    }
  }, [onOAuthProceedClick]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.title}>{serviceName} OAuth</div>
        <div className={styles.description}>
          If you click "Proceed" you'll be redirected to authentication through {serviceName}.
        </div>
        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} color="secondary" fullWidth>
            Cancel
          </Button>
          <Button onClick={handleOAuthProceedClick} fullWidth>
            Proceed
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OAuthModal;
