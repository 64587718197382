import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// libs
import cx from 'classnames';
// hooks
import useKeyPress from 'hooks/useKeyPress';
// icons
import { ReactComponent as SearchIcon } from 'assets/search-sm.svg';
// styles
import styles from './menu.module.css';

export type Item = {
  id: string;
  label: string | React.ReactNode;
  value: any;
};

export interface MenuItemProps {
  item: Item;
  setSelected: (item: Item) => void;
  className?: string;
  active?: boolean;
  selected?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ item, active, selected, setSelected, className = '' }) => {
  return (
    <li
      className={cx(styles.menuItem, { [styles.selected]: selected, [styles.active]: active }, className)}
      onClick={() => {
        setSelected(item);
      }}
    >
      <div className={styles.menuItemContent}>
        {item?.value?.icon || null}
        {item.label}
      </div>
    </li>
  );
};

interface MenuProps {
  items: Item[];
  selectedItems: Item[];
  onMenuItemClick: (item: Item) => void;
  showSearchInput?: boolean;
  menuClassName?: string;
  menuItemClassName?: string;
}

const Menu: React.FC<MenuProps> = ({
  items,
  selectedItems,
  onMenuItemClick,
  showSearchInput,
  menuClassName = '',
  menuItemClassName = '',
}) => {
  const [search, setSearch] = useState('');
  const menuRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');
  const [cursor, setCursor] = useState<number>(-1);

  const filteredItems = useMemo(
    () =>
      items.filter((item) => {
        const searchText = search.toLowerCase().replace(/ /g, '');
        const { label } = item;
        if (typeof label === 'string') {
          const labelText = label.toLowerCase().replace(/ /g, '');
          return labelText.includes(searchText) && !selectedItems.some((selectedItem) => selectedItem.id === item.id);
        }
        return true;
      }),
    [items, search, selectedItems],
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value),
    [],
  );

  const handleMenuItemClick = useCallback((item: Item) => onMenuItemClick(item), [onMenuItemClick]);

  useEffect(() => {
    if (searchRef?.current) {
      searchRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (items.length && downPress) {
      setCursor((prevState) => (prevState < items.length - 1 ? prevState + 1 : prevState));
    }
  }, [downPress, items]);

  useEffect(() => {
    if (items.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [items, upPress]);

  useEffect(() => {
    if (items.length && enterPress) {
      if (cursor > -1) {
        handleMenuItemClick(items[cursor]);
      }
    }
  }, [cursor, enterPress, handleMenuItemClick, items]);

  return (
    <div ref={menuRef}>
      {showSearchInput ? (
        <div className={styles.searchWrapper}>
          <SearchIcon width={12} height={12} className={styles.searchIcon} />
          <input
            placeholder="Search Filters"
            className={styles.searchInput}
            type="text"
            value={search}
            onChange={handleSearchChange}
            ref={searchRef}
          />
        </div>
      ) : null}
      <ul className={cx(styles.menu, { [styles.withoutSearch]: !showSearchInput }, menuClassName)}>
        {filteredItems.map((item, index) => (
          <MenuItem
            item={item}
            key={index}
            className={menuItemClassName}
            active={cursor === index}
            setSelected={handleMenuItemClick}
          />
        ))}
      </ul>
    </div>
  );
};

export default Menu;
