import I from 'seamless-immutable';
import { UIState } from 'store/ui/types';

export const uiStateAdapter = (): UIState =>
  I({
    hideSidebar: false,
    isOAuthLoading: false,
    isSyncFieldLoading: false,
    isSyncAllLoading: false,
    isServiceFieldsLoading: false,
    isServiceActionLoading: false,
    slackChannelsMeta: {
      isChannelsLoading: false,
      isAddingToChannelLoading: false,
      isRemoveFromChannelLoading: false,
    },
  });
