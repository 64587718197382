import { useCallback } from 'react';
// libs
import { useDispatch } from 'react-redux';
// store
import { clearToken } from '../store/auth/actions';

const useLogout = () => {
  const dispatch = useDispatch();

  const logout = useCallback(() => dispatch(clearToken()), [dispatch]);

  return { logout };
};

export default useLogout;
