import { useCallback, useState } from 'react';
// libs
import { useDispatch, useSelector } from 'react-redux';
// hooks
import useApi, { GetServiceFieldsParams } from './useApi';
// store
import { Resource, ServiceType } from '../../store/members/types';
import { setIsServiceFieldsLoading } from '../../store/ui/actions';
import { isServiceFieldsLoadingSelector } from '../../store/ui/selector';

export type ServiceFields = {
  [index: string]: {
    from: {
      service: ServiceType;
      resource: Resource;
      fields: string[];
    };
    rule: string;
  }[];
};

const useGetServiceFields = () => {
  const [data, setData] = useState<ServiceFields>();

  const { getServiceFields: getFields } = useApi();

  const dispatch = useDispatch();

  const isLoading = useSelector(isServiceFieldsLoadingSelector);

  const getServiceFields = useCallback(
    (params: GetServiceFieldsParams) => {
      dispatch(setIsServiceFieldsLoading(true));

      return getFields(params)
        .then((response) => {
          setData(response.data);
          return response.data as ServiceFields;
        })
        .catch(() => undefined)
        .finally(() => dispatch(setIsServiceFieldsLoading(false)));
    },
    [dispatch, getFields],
  );

  return { isLoading, getServiceFields, serviceFieldsData: data };
};

export default useGetServiceFields;
