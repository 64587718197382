import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import store from 'store';
import { clearToken } from 'store/auth/actions';

enum StatusCode {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'X-Requested-With': 'XMLHttpRequest',
};

const TIMEOUT = 60000;

const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const accessToken = store.getState()?.auth?.token;

    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config!.headers!.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  } catch (error) {
    throw new Error(error as string);
  }
};

class Http {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL: process.env.REACT_APP_API_URL ?? 'https://api.sync.brocoders.xyz/',
      timeout: TIMEOUT,
      headers,
    });

    http.interceptors.request.use(injectToken, (error) => Promise.reject(error));

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        return Http.handleError(response);
      },
    );

    this.instance = http;
    return http;
  }

  request<T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  private static handleError(error: any) {
    const { status } = error;

    switch (status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        toast('Something went wrong, try again later...', { type: 'error' });
        break;
      }
      case StatusCode.Forbidden: {
        // Handle Forbidden
        break;
      }
      case StatusCode.NotFound: {
        // Handle NotFound
        break;
      }
      case StatusCode.BadRequest: {
        // Handle BadRequest
        break;
      }
      case StatusCode.Unauthorized: {
        // Handle Unauthorized
        store.dispatch(clearToken());
        break;
      }
      case StatusCode.UnprocessableEntity: {
        break;
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break;
      }
      default: {
        break;
      }
    }

    return Promise.reject(error);
  }
}

export default new Http();
