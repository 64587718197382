import { FC } from 'react';
import Wrapper from 'containers/wrapper';

const Home: FC = () => {
  return (
    <Wrapper title="Welcome">
      <div>welcome</div>
    </Wrapper>
  );
};

export default Home;
