import client from 'helpers/apiClient';
import { AxiosResponse } from 'axios';
// types
import { Resource, ServiceKey, ServiceType } from '../../store/members/types';

export type PromisedResponse = Promise<AxiosResponse>;

export type LoginParams = {
  email: string;
  password: string;
};

export type SyncAllParams = {
  mainService: {
    serviceName: ServiceKey;
    personIdentifier: {
      [key in ServiceKey]?: string;
    };
  };
  recipientServices: {
    [key in ServiceKey]?: string;
  };
};

export type SyncFieldParams = {
  field: string;
  from: {
    service: ServiceType;
    resource: Resource;
    externalIdValue: string;
  };
  to: {
    service: ServiceType;
    resource: Resource;
    externalIdValue: string;
  };
};

export type GetServiceFieldsParams = {
  service: ServiceType;
  resource: Resource;
};

export type RegisterMemberField = {
  field: string;
  service: ServiceType;
  resource: Resource;
  externalIdValue: string;
};

export type RegisterMemberData = {
  service: ServiceType;
  resource: Resource;
  fields: RegisterMemberField[];
};

export type MemberActionsData = {
  service: ServiceType;
  resource: Resource;
  externalId: string;
};

export type AddUserToSlackParams = {
  userId: string;
  channelId: string;
};

export type RemoveUserFromSlackParams = AddUserToSlackParams;

export type IUseApi = {
  example: () => PromisedResponse;
  getTeamMembers: () => PromisedResponse;
  getOneMember: (query: string) => PromisedResponse;
  getProfile: () => PromisedResponse;
  getOAuthLink: (serviceName: string, query: string) => PromisedResponse;
  getServiceFields: (params: GetServiceFieldsParams) => PromisedResponse;
  getSlackChannels: (slackId: string) => PromisedResponse;
  login: (data: LoginParams) => PromisedResponse;
  syncField: (data: SyncFieldParams) => PromisedResponse;
  syncAll: (data: SyncAllParams) => PromisedResponse;
  registerMember: (data: RegisterMemberData) => PromisedResponse;
  archiveMember: (data: MemberActionsData) => PromisedResponse;
  removeMember: (data: MemberActionsData) => PromisedResponse;
  unArchiveMember: (data: MemberActionsData) => PromisedResponse;
  restoreMember: (data: MemberActionsData) => PromisedResponse;
  addUserToSlackChannel: (data: AddUserToSlackParams) => PromisedResponse;
  deleteUserFromSlackChannel: (data: RemoveUserFromSlackParams) => PromisedResponse;
};

// USE current hook only inside other hooks!!!

const useApi = (): IUseApi => {
  return {
    example: () => client.get('example'),
    getTeamMembers: () => client.get('/team-members'),
    getOneMember: (query: string) => client.get(`https://api.sync.brocoders.xyz/team-members/show/${query}`),
    getProfile: () => client.get('auth/profile'),
    getOAuthLink: (serviceName, query) => client.get(`oauth/${serviceName}/link${query}`),
    getServiceFields: ({ service, resource }: GetServiceFieldsParams) =>
      client.get(`team-members/register?service=${service}&resource=${resource}`),
    getSlackChannels: (slackId) => client.get(`/slack/channels?filters[user]=${slackId}`),
    login: (data: LoginParams) => client.post(`/auth/login`, data),
    syncField: (data: SyncFieldParams) => client.put(`/team-members/sync-field`, data),
    syncAll: (data: SyncAllParams) => client.put(`/team-members/sync`, data),
    registerMember: (data: RegisterMemberData) => client.post('/team-members/register', data),
    archiveMember: (data: MemberActionsData) => client.delete('/team-members/archive', { data }),
    removeMember: (data: MemberActionsData) => client.delete('/team-members/remove', { data }),
    unArchiveMember: (data: MemberActionsData) => client.put('/team-members/unArchive', data),
    restoreMember: (data: MemberActionsData) => client.put('/team-members/restore', data),
    addUserToSlackChannel: ({ channelId, userId }: AddUserToSlackParams) =>
      client.post(`/slack/channels/user`, { channel: channelId, user: userId }),
    deleteUserFromSlackChannel: ({ channelId, userId }: RemoveUserFromSlackParams) =>
      client.delete(`/slack/channels/user`, { data: { channel: channelId, user: userId } }),
  };
};

export default useApi;
