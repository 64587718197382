import { SERVICE_TYPES } from '../store/members/types';

export type ServiceTypeTitle = {
  [K in typeof SERVICE_TYPES[number]]: string;
};

export const SERVICE_TYPE_TO_TITLE: ServiceTypeTitle = {
  broSync: 'Bro Sync',
  broReports: 'Bro Reports',
  float: 'Float',
  github: 'GitHub',
  googleSheets: 'Google Sheets',
  gSuite: 'G Suite',
  jira: 'Jira',
  notion: 'Notion',
  slack: 'Slack',
};
