import React from 'react';
import { FieldProps } from 'formik';
import Select, { SelectOption } from '../../select';

export interface SelectFieldProps extends FieldProps {
  options: SelectOption[];
}

const SelectField: React.FC<SelectFieldProps> = ({ field, form, options, ...rest }) => {
  return (
    <Select
      {...rest}
      options={options}
      name={field.name}
      value={options ? options.find((option) => option.value === field.value) : ''}
      onBlur={() => field.onBlur}
      onChange={(newValue) => {
        form.setFieldValue(field.name, newValue);
      }}
    />
  );
};

export default SelectField;
