import I from 'seamless-immutable';
import { sortBy } from 'lodash';
import { ApiProfile, ApiShowServices, ProfileState, ShowServices } from './types';
import { Resource, ServiceKey, ServiceType } from '../members/types';
import { SERVICE_TYPE_TO_TITLE } from '../../constants/general';

export const profileDefaultStateAdapter = (): ProfileState =>
  I({
    profile: {
      id: 0,
      email: '',
      name: 'Unknown User',
      avatar: '',
      serviceAuth: {},
      showServices: [],
      paidServices: [],
    },
    isLoading: false,
  });

export const showServicesProfileAdapter = (data?: ApiShowServices): ShowServices => {
  if (data) {
    const mappedHeaders = Object.entries(data).flatMap(([key, value]) => {
      const resources = [...value] as Resource[];

      return resources.reduce((prevRes, currRes) => {
        const currentServiceTitle = SERVICE_TYPE_TO_TITLE[key as ServiceType]
          ? SERVICE_TYPE_TO_TITLE[key as ServiceType]
          : key;
        const serviceType = key as ServiceType;
        const resource = currRes as Resource;
        const serviceTypeResource = key !== resource ? `${key} ${resource}` : key;
        const serviceTitle = key !== resource ? `${currentServiceTitle} ${resource}` : currentServiceTitle;
        const serviceKey = serviceTypeResource.replace(/\s/g, '') as ServiceKey;
        return [...prevRes, { serviceType, serviceTitle, serviceKey, resource }];
      }, [] as ShowServices);
    }, []);

    return sortBy(mappedHeaders, (header) => header?.serviceKey?.toLowerCase());
  }

  return [];
};

export const profileAdapter = (data?: ApiProfile): ProfileState['profile'] => {
  if (data) {
    const avatarSize = 'image_72';
    const avatar = data?.avatars?.find((avatar) => avatar[avatarSize])?.[avatarSize] || '';
    const profile = {
      ...data,
      name: data?.name || 'Unknown User',
      avatar,
      showServices: showServicesProfileAdapter(data.showServices),
      paidServices: data.paidServices,
    };
    return I({ ...profile });
  }

  return I({
    id: 0,
    email: '',
    name: 'Unknown User',
    avatar: '',
    serviceAuth: {},
    showServices: [],
    paidServices: [],
  });
};
