import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import exampleStore from './example';
import ui from './ui';
import members from './members';
import auth from './auth';
import profile from './profile';

const reducers = combineReducers({
  example: exampleStore,
  ui,
  members,
  auth,
  profile,
});

const store = configureStore({
  reducer: reducers,
});

export default store;
