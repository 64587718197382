import { useCallback } from 'react';
// libs
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// store
import { setIsSyncFieldLoading } from '../../store/ui/actions';
import { isSyncFieldLoadingSelector } from '../../store/ui/selector';
// hooks
import useApi, { SyncFieldParams } from './useApi';
import useMembers from './useMembers';

const useSyncField = () => {
  const { search } = useLocation();

  const dispatch = useDispatch();

  const { syncField: synchronizeField } = useApi();

  const { getOne } = useMembers();

  const isLoading = useSelector(isSyncFieldLoadingSelector);

  const syncField = useCallback(
    (data: SyncFieldParams) => {
      dispatch(setIsSyncFieldLoading(true));

      synchronizeField(data)
        .then(() => {
          toast(`${data.field} is successfully synchronized`, { type: 'success' });
        })
        .catch((error) => toast(error, { type: 'error' }))
        .finally(() => {
          getOne(search);
          dispatch(setIsSyncFieldLoading(false));
        });
    },
    [dispatch, getOne, search, synchronizeField],
  );

  return { syncField, isLoading };
};

export default useSyncField;
