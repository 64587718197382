import { Action } from 'store/types';
import { MembersState } from './types';
import { membersStateAdapter, oneMemberAdapter, membersAdapter } from './adapters';
import { setMembersList, setMember, clearMember, setIsLoading } from './actions';

const initialStore = membersStateAdapter();

const reducer = (state: MembersState = initialStore, action: Action) => {
  switch (action.type) {
    case setMembersList.type: {
      return state.set('list', membersAdapter(action.payload));
    }
    case setMember.type: {
      return state.set('one', oneMemberAdapter(action.payload));
    }
    case clearMember.type: {
      return state.set('one', oneMemberAdapter());
    }
    case setIsLoading.type: {
      return state.set('isLoading', action.payload);
    }

    default: {
      return state;
    }
  }
};

export default reducer;
