import { useCallback, useState } from 'react';
import { ServiceToSync, SyncFieldClickParams } from '../../../../types';

const INITIAL_SYNC_FIELD = { title: null, resource: null, type: null, key: null, field: null };

const useSelectedSyncService = () => {
  const [selectedSyncService, setSelectedSyncService] = useState<ServiceToSync>(INITIAL_SYNC_FIELD);

  const handleSelectedSyncServiceChange = useCallback(
    ({ serviceTitle, serviceType, field, serviceKey, resource }: SyncFieldClickParams) => {
      setSelectedSyncService({
        ...selectedSyncService,
        title: serviceTitle,
        field: field || selectedSyncService?.field,
        type: serviceType,
        resource,
        key: serviceKey,
      });
    },
    [selectedSyncService],
  );

  return { selectedSyncService, handleSelectedSyncServiceChange };
};

export default useSelectedSyncService;
