import React, { useMemo } from 'react';
// components
import Switch from 'components/switch';
import Field from '../../../field';
// styles
import styles from '../../services.module.css';

export interface Params {
  isGiveAccessDisabled?: boolean;
}

const useGithubIntegration = ({ isGiveAccessDisabled }: Params) => {
  const integrationInfo = useMemo(
    () => [
      {
        id: '1',
        name: (
          <Field className={styles.integrationsTableItemField} weight="medium">
            Give access to Brocoders repositories
          </Field>
        ),
        action: <Switch disabled={isGiveAccessDisabled} />,
      },
    ],
    [isGiveAccessDisabled],
  );

  return { integrationInfo };
};

export default useGithubIntegration;
