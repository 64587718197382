import I from 'seamless-immutable';
import { AuthState } from 'store/auth/types';

const token = localStorage.getItem('token') || '';

export const authDefaultStateAdapter = (): AuthState =>
  I({
    token,
    isLoading: false,
  });

export const authStateAdapter = (data: { access_token?: string }) => {
  if (data?.access_token) {
    return I(data.access_token);
  }

  return I('');
};
