import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import useProfile from './hooks/api/useProfile';
import Routes from './routes';
import MembersTableFiltersProvider from './pages/members/components/membersTableFilters/providers/membersTableFiltersProvider';
// styles
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const { getProfile } = useProfile();

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <BrowserRouter>
      <MembersTableFiltersProvider>
        <Routes />
      </MembersTableFiltersProvider>
      <ToastContainer theme="colored" />
    </BrowserRouter>
  );
};

export default App;
