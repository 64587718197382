import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Item } from '../../filterType/menu';
import { Item as FilterItem } from '../../filter/menu';

export interface IMembersTableFiltersContext {
  selectedFilterType: Item | undefined;
  selectedFilters: FilterItem[];
  setSelectedFilterType: (item: Item) => void;
  setSelectedFilters: (items: FilterItem[]) => void;
}

export interface TableFiltersProviderProps {
  children: React.ReactNode;
}

const MembersTableFiltersContext = React.createContext<IMembersTableFiltersContext>(
  null as unknown as IMembersTableFiltersContext,
);

export const useMembersTableFiltersContext = () => useContext(MembersTableFiltersContext);

const MembersTableFiltersProvider: React.FC<TableFiltersProviderProps> = ({ children }) => {
  const [selectedFilterType, setSelectedFilterType] = useState<Item | undefined>(undefined);
  const [selectedFilters, setSelectedFilters] = useState<FilterItem[]>([]);

  const handleSelectedFilterTypeChange = useCallback((item: Item) => setSelectedFilterType(item), []);
  const handleSelectedFiltersChange = useCallback((items: FilterItem[]) => setSelectedFilters(items), []);

  const values = useMemo(
    () => ({
      selectedFilterType,
      selectedFilters,
      setSelectedFilterType: handleSelectedFilterTypeChange,
      setSelectedFilters: handleSelectedFiltersChange,
    }),
    [handleSelectedFilterTypeChange, handleSelectedFiltersChange, selectedFilterType, selectedFilters],
  );

  return <MembersTableFiltersContext.Provider value={values}>{children}</MembersTableFiltersContext.Provider>;
};

export default MembersTableFiltersProvider;
