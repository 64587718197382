import React, { FC, useMemo } from 'react';
// images
import avatarsGroup from 'assets/avatarsGroup2.png';
import logo from 'assets/logo.svg';
// components
import Title from '../../components/pageTitle';
import Button from '../../components/button';
import Social from '../../components/social';
// styles
import styles from './login.module.css';

const Login: FC = () => {
  const googleAuthLink = useMemo(() => `${process.env.REACT_APP_API_URL}auth/login/oauth`, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.leftColumn}>
          <img className={styles.logo} src={logo} alt="logo" />
          <div className={styles.loginContainer}>
            <div className={styles.loginForm}>
              <Title>Sign In</Title>
              <h3 className={styles.loginDescription}>
                Please use your <span className={styles.email}>@brocoders.team</span> account to sing in
              </h3>
              <a href={googleAuthLink} className={styles.googleLoginLink}>
                <Button
                  className={styles.googleLoginButton}
                  color="secondary"
                  startIcon={<Social icon="gSuite" className={styles.googleIcon} />}
                  fullWidth
                >
                  Sign in with Google
                </Button>
              </a>
            </div>
          </div>
          <h2 className={styles.footerText}>© Brocoders 2022</h2>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.backgroundImage} />
          <div className={styles.background} />
          <div className={styles.textContainer}>
            <Title className={styles.title}>Start turning your ideas into reality with Brocoders.</Title>
            <h2 className={styles.description}>
              Create a free account and get full access to all the features of our spy community. Get started in 2
              minutes.
            </h2>
            <div className={styles.avatarsContainer}>
              <img className={styles.avatars} src={avatarsGroup} alt="avatars-group" />
              <h2 className={styles.description}>Join and become a BRO</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
