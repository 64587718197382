import React, { useCallback, useRef, useState, useEffect } from 'react';
// libs
import cx from 'classnames';
// hooks
import useKeyPress from 'hooks/useKeyPress';
// icons
import { ReactComponent as CheckIcon } from 'assets/check.svg';
// styles
import styles from './menu.module.css';

export type Item = {
  label: string;
  value: any;
};

export interface MenuItemProps {
  item: Item;
  setSelected: (item: Item) => void;
  className?: string;
  active?: boolean;
  selected?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ item, active, selected, setSelected, className = '' }) => {
  return (
    <li
      className={cx(styles.menuItem, { [styles.selected]: selected, [styles.active]: active }, className)}
      value={item.value}
      onClick={() => setSelected(item)}
    >
      {item.label}
      <div className={styles.menuItemIcon}>{selected ? <CheckIcon /> : null}</div>
    </li>
  );
};

export interface MenuProps {
  items: Item[];
  selectedItem: Item | undefined;
  onMenuItemClick: (item: Item) => void;
  menuClassName?: string;
  menuItemClassName?: string;
}

const Menu: React.FC<MenuProps> = ({
  items,
  selectedItem,
  onMenuItemClick,
  menuClassName = '',
  menuItemClassName = '',
}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const enterPress = useKeyPress('Enter');
  const [cursor, setCursor] = useState<number>(-1);

  const handleMenuItemClick = useCallback(
    (item: Item) => {
      onMenuItemClick(item);
    },
    [onMenuItemClick],
  );

  useEffect(() => {
    if (items.length && downPress) {
      setCursor((prevState) => (prevState < items.length - 1 ? prevState + 1 : prevState));
    }
  }, [downPress, items]);

  useEffect(() => {
    if (items.length && upPress) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    }
  }, [upPress, items]);

  useEffect(() => {
    if (items.length && enterPress) {
      if (cursor > -1) {
        handleMenuItemClick(items[cursor]);
      }
    }
  }, [cursor, enterPress, handleMenuItemClick, items]);

  return (
    <div ref={menuRef}>
      <ul className={cx(styles.menu, menuClassName)} ref={listRef}>
        {items.map((item, index) => (
          <MenuItem
            item={item}
            key={index}
            className={menuItemClassName}
            active={cursor === index}
            selected={selectedItem ? items.indexOf(selectedItem) === index : false}
            setSelected={handleMenuItemClick}
          />
        ))}
      </ul>
    </div>
  );
};

export default Menu;
