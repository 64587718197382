import React, { FC, useCallback, useMemo, useState } from 'react';
// libs
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
// types
import { ServiceType } from 'store/members/types';
// hooks
import useUI from 'hooks/useUI';
import useLogout from 'hooks/useLogout';
import useOAuthLink from 'hooks/api/useOAuthLink';
import useProfile from 'hooks/api/useProfile';
// components
import Icon, { Icons } from 'components/icon';
import Avatar from 'components/avatar';
import Social, { Icons as SocialIcons } from 'components/social';
import Button from 'components/button';
// images
import logo from './logo.svg';
import logo_small from './logo_small.svg';
// styles
import styles from './sidebar.module.css';

type ListLink = {
  label: string;
  to: string;
  icon: Icons;
};

const INITIAL_LOADING_STATE: Record<ServiceType, boolean> = {
  jira: false,
  slack: false,
  github: false,
  gSuite: false,
  float: false,
  notion: false,
  googleSheets: false,
  broSync: false,
  broReports: false,
};

const Sidebar: FC = () => {
  const { profile } = useProfile();

  const [loadingState, setLoadingState] = useState<Record<ServiceType, boolean>>(INITIAL_LOADING_STATE);

  const { hideSidebar } = useUI();

  const { logout } = useLogout();

  const { getOAuthLink } = useOAuthLink();

  const handleAuthenticateClick = useCallback(
    (serviceType: ServiceType) => async () => {
      setLoadingState({ ...loadingState, [serviceType]: true });
      window.location.href = await Promise.resolve(getOAuthLink(serviceType));
      // setLoadingState({ ...loadingState, [serviceType]: false });
    },
    [getOAuthLink, loadingState],
  );

  const links: ListLink[] = useMemo(
    () => [
      {
        label: 'Team members',
        to: '/members',
        icon: 'team',
      },
      {
        label: 'Settings',
        to: '/settings',
        icon: 'cog',
      },
    ],
    [],
  );

  const oAuthButtons = useMemo(() => {
    return Object.entries(profile.serviceAuth).map(([key, value]) => {
      const serviceType = key as ServiceType;
      const icon = key as SocialIcons;
      const isLoading = loadingState[serviceType as keyof typeof loadingState];
      const isDisabled = Object.entries(loadingState).some(
        ([loadingKey, loadingValue]) => loadingKey !== serviceType && loadingValue,
      );

      return (
        <div className={styles.socialContainer} key={key}>
          <Social icon={icon} className={cx(styles.socialIcon)} />
          <Button
            color="secondary"
            className={styles.authButton}
            onClick={handleAuthenticateClick(serviceType)}
            isLoading={isLoading}
            disabled={isDisabled}
          >
            Authenticate
          </Button>
          <span
            className={cx(styles.authStatusBadge, {
              [styles.auth]: value,
              [styles.unauth]: !value,
            })}
          />
        </div>
      );
    });
  }, [handleAuthenticateClick, loadingState, profile.serviceAuth]);

  return (
    <div className={cx(styles.wrapper, { [styles.minimize]: hideSidebar })}>
      <div className={styles.logo}>
        <img src={hideSidebar ? logo_small : logo} alt="logo" draggable={false} />
      </div>
      <div className={cx(styles.list, styles.links)}>
        {links.map((item: ListLink, idx: number) => (
          <NavLink key={idx} to={item.to} className={({ isActive }) => cx(styles.link, { [styles.active]: isActive })}>
            <Icon icon={item.icon} className={styles.icon} />
            {hideSidebar ? null : <span className={styles.text}>{item.label}</span>}
          </NavLink>
        ))}
        <div className={styles.socialsContainer}>
          <div className={styles.oAuthTitle}>OAuth</div>
          {oAuthButtons}
        </div>
      </div>
      <div className={cx(styles.list)}>
        <div className={styles.link}>
          <Avatar src={profile?.avatar} alt={profile?.name} className={styles.avatar} />
          {hideSidebar ? null : (
            <>
              <span className={styles.text}>{profile?.name}</span>
              <button onClick={logout} type="button" className={styles.logoutButton}>
                <Icon icon="exit" className={styles.logout} />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
