import React, { useCallback } from 'react';
// hooks
import useServiceActions from 'hooks/api/useServiceActions';
// components
import Modal from 'components/modal';
import Button from 'components/button';
// images
import deleteBucket from 'assets/deleteBucket.svg';
// styles
import styles from './deleteModal.module.css';

export interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceName: string;
  onDelete?: () => void;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ isOpen, onClose, onDelete, serviceName }) => {
  const handleDeleteClick = useCallback(() => {
    if (onDelete) {
      onDelete();
    }
  }, [onDelete]);

  const { isLoading } = useServiceActions();

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={styles.container}>
        <img width={56} height={56} src={deleteBucket} alt="delete-bucket" />
        <div className={styles.title}>Delete user from {serviceName}</div>
        <div className={styles.description}>User data will be deleted from {serviceName} permanently.</div>
        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} color="secondary" fullWidth>
            Cancel
          </Button>
          <Button onClick={handleDeleteClick} fullWidth isLoading={isLoading}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
