import { useCallback, useMemo } from 'react';
// libs
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// types
import { AxiosResponse } from 'axios';
import { ServiceType } from '../../store/members/types';
// store
import { setIsOAuthLinkLoading } from '../../store/ui/actions';
import { isOAuthLinkLoadingSelector } from '../../store/ui/selector';
// hooks
import useApi from './useApi';

export interface Params {}

const useOAuthLink = () => {
  const { search } = useLocation();

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const { getOAuthLink: getLink } = useApi();

  const isLoading = useSelector(isOAuthLinkLoadingSelector);

  const baseUrl = useMemo(() => process.env.REACT_APP_HOST || '', []);

  const encodedRedirectUri = useMemo(
    () => encodeURIComponent(`${baseUrl}${pathname.substring(1)}${search}`),
    [baseUrl, pathname, search],
  );

  const query = useMemo(() => `?redirect_uri=${encodedRedirectUri}`, [encodedRedirectUri]);

  const getOAuthLink = useCallback(
    async (serviceType: ServiceType) => {
      dispatch(setIsOAuthLinkLoading(true));

      const response: AxiosResponse = await getLink(serviceType, query)
        .catch((error) => error)
        .finally(() => dispatch(setIsOAuthLinkLoading(false)));

      return response?.data?.authLink as string;
    },
    [dispatch, getLink, query],
  );

  return { getOAuthLink, isLoading };
};

export default useOAuthLink;
