import React, { FC, useState, useMemo, useCallback } from 'react';
// libs
import cx from 'classnames';
import { Column, useFilters, useGlobalFilter, useSortBy, useTable } from 'react-table';
import InfiniteScroll from 'react-infinite-scroller';
// styles
import styles from './membersTable.module.css';

export type Data = any[];
export type Columns = Column[];

export type MembersTableProps = {
  columns: Columns;
  data?: Data;
  className?: string;
  infinite?: {
    pageStart: number;
    hasMore: boolean;
    loadMore: (page: number) => void;
    initialLoad: boolean;
  };
  empty?: string;
  sticky?: boolean;
  onClickRow?: (id: number) => void;
};

const DefaultColumnFilter = () => {
  return null;
};

const Table: FC<MembersTableProps> = (props) => {
  const { columns, data = [], className, infinite, sticky, onClickRow, empty = 'No data' } = props;

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data, defaultColumn },
    useFilters,
    useGlobalFilter,
    useSortBy,
  );

  const [selectedRow, onChangeSelectedRow] = useState<number>();

  const onSelectRow = useCallback(
    (id: number) => {
      if (onClickRow) {
        const newSelectedRow = selectedRow === id ? undefined : id;
        onChangeSelectedRow(newSelectedRow);
        onClickRow(id);
      }
    },
    [onClickRow, onChangeSelectedRow, selectedRow],
  );

  const list = useMemo(
    () =>
      rows.map((row, idx) => {
        prepareRow(row);
        const id = Number(row.id);
        return (
          <tr
            {...row.getRowProps()}
            key={idx}
            onClick={() => onSelectRow(id)}
            className={cx({ [styles.active]: idx === selectedRow, [styles.firstSticky]: sticky })}
          >
            {row.cells.map((cell, index) => {
              return (
                <td {...cell.getCellProps()} key={index}>
                  {cell.render('Cell')}
                </td>
              );
            })}
          </tr>
        );
      }),
    [onSelectRow, prepareRow, rows, selectedRow, sticky],
  );

  return (
    <div className={cx(className, styles.container, { [styles.sticky]: sticky })}>
      <table className={styles.wrapper} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index} className={cx({ [styles.firstSticky]: sticky })}>
              {headerGroup.headers.map((column, i) => (
                <td {...column.getHeaderProps(column.getSortByToggleProps())} key={i}>
                  <div className={styles.headerContent}>
                    {column.render('Header')}
                    {column.canFilter ? column.render('Filter') : null}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </thead>
        {infinite ? (
          <InfiniteScroll element="tbody" {...getTableBodyProps()} {...infinite}>
            {list}
          </InfiniteScroll>
        ) : (
          <tbody {...getTableBodyProps()}>{list}</tbody>
        )}
      </table>
      {list.length < 1 ? (
        <div className={styles.empty}>
          <p>{empty}</p>
        </div>
      ) : null}
    </div>
  );
};

export default Table;
