const FlagGb = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4744_394136)">
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="#F0F0F0"
      />
      <path
        d="M1.65355 3.12866C1.02514 3.94626 0.551297 4.8886 0.275391 5.91232H4.4372L1.65355 3.12866Z"
        fill="#0052B4"
      />
      <path
        d="M15.7243 5.91241C15.4483 4.88872 14.9745 3.94638 14.3461 3.12878L11.5625 5.91241H15.7243Z"
        fill="#0052B4"
      />
      <path
        d="M0.275391 10.0868C0.551328 11.1105 1.02517 12.0529 1.65355 12.8704L4.43711 10.0868H0.275391Z"
        fill="#0052B4"
      />
      <path
        d="M12.8701 1.65318C12.0525 1.02478 11.1101 0.550932 10.0864 0.274994V4.43678L12.8701 1.65318Z"
        fill="#0052B4"
      />
      <path
        d="M3.12891 14.3451C3.9465 14.9735 4.88885 15.4474 5.91253 15.7233V11.5616L3.12891 14.3451Z"
        fill="#0052B4"
      />
      <path
        d="M5.9125 0.274994C4.88881 0.550931 3.94647 1.02477 3.12891 1.65315L5.9125 4.43674V0.274994Z"
        fill="#0052B4"
      />
      <path
        d="M10.0864 15.7233C11.1101 15.4474 12.0525 14.9735 12.87 14.3451L10.0864 11.5616V15.7233Z"
        fill="#0052B4"
      />
      <path
        d="M11.5625 10.0868L14.3461 12.8704C14.9745 12.0529 15.4483 11.1105 15.7243 10.0868H11.5625Z"
        fill="#0052B4"
      />
      <path
        d="M15.9323 6.95653H9.04353H9.0435V0.0677188C8.70191 0.02325 8.35366 0 8 0C7.64628 0 7.29809 0.02325 6.95653 0.0677188V6.95647V6.9565H0.0677188C0.02325 7.29809 0 7.64634 0 8C0 8.35372 0.02325 8.70191 0.0677188 9.04347H6.95647H6.9565V15.9323C7.29809 15.9768 7.64628 16 8 16C8.35366 16 8.70191 15.9768 9.04347 15.9323V9.04353V9.0435H15.9323C15.9768 8.70191 16 8.35372 16 8C16 7.64634 15.9768 7.29809 15.9323 6.95653Z"
        fill="#D80027"
      />
      <path
        d="M10.0869 10.0875L13.6568 13.6574C13.821 13.4932 13.9776 13.3217 14.127 13.1438L11.0707 10.0875H10.0869V10.0875Z"
        fill="#D80027"
      />
      <path
        d="M5.91317 10.0875H5.91311L2.34326 13.6573C2.50739 13.8215 2.67898 13.9781 2.85686 14.1276L5.91317 11.0712V10.0875Z"
        fill="#D80027"
      />
      <path
        d="M5.91268 5.91274V5.91268L2.34281 2.34277C2.17862 2.5069 2.022 2.67849 1.87256 2.85637L4.9289 5.91271H5.91268V5.91274Z"
        fill="#D80027"
      />
      <path
        d="M10.0869 5.91345L13.6568 2.34351C13.4927 2.17932 13.3211 2.0227 13.1432 1.87329L10.0869 4.92964V5.91345Z"
        fill="#D80027"
      />
    </g>
    <defs>
      <clipPath id="clip0_4744_394136">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FlagGb;
