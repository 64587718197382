import React, { useCallback, useEffect, useState } from 'react';
// libs
import cx from 'classnames';
// components
import PopoverMenu from 'components/popover';
import Menu, { Item } from 'pages/members/components/membersTableFilters/filterType/menu';
// providers
import { useMembersTableFiltersContext } from 'pages/members/components/membersTableFilters/providers/membersTableFiltersProvider';
// icons
import { ReactComponent as FilterIcon } from 'assets/filter.svg';
// styles
import styles from './filterType.module.css';

export interface FilterMenuProps {
  items: Item[];
}

// TODO: refactor this and Filter component
const FilterType: React.FC<FilterMenuProps> = ({ items }) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const { selectedFilterType, setSelectedFilterType } = useMembersTableFiltersContext();

  const handleIsMenuOpenedChange = useCallback((isOpen: boolean) => setIsMenuOpened(isOpen), []);

  const handleMenuItemClick = useCallback(
    (selectedItem: Item) => {
      setSelectedFilterType(selectedItem);
      setIsMenuOpened(false);
    },
    [setSelectedFilterType],
  );

  useEffect(() => {
    setSelectedFilterType(items[0]);
  }, []);

  return (
    <PopoverMenu
      isOpen={isMenuOpened}
      setIsOpen={handleIsMenuOpenedChange}
      button={
        <button className={cx(styles.menuButton, { [styles.open]: isMenuOpened })} type="button">
          <FilterIcon width={16} height={16} />
          {selectedFilterType?.label}
        </button>
      }
      content={<Menu items={items} selectedItem={selectedFilterType} onMenuItemClick={handleMenuItemClick} />}
    />
  );
};

export default FilterType;
