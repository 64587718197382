import { useCallback, useMemo, useState } from 'react';
// hooks
import useProfile from 'hooks/api/useProfile';
import useSyncField from 'hooks/api/useSyncField';
import useSyncAll from 'hooks/api/useSyncAll';
import useOAuthLink from 'hooks/api/useOAuthLink';
import useMembers from 'hooks/api/useMembers';
import useServiceActions from 'hooks/api/useServiceActions';
import useModals from './useModals';
import useSelectedSyncService from './useSelectedSyncService';
import useInfo from './useInfo';
import useServiceFieldsData from './useServiceFieldsData';
// types
import { ServiceToSync, SyncFieldClickParams } from '../../../../types';
import { Resource, ServiceKey, ServiceType } from '../../../../../../store/members/types';

export interface Params {
  serviceType: ServiceType;
  serviceResource: Resource;
  serviceTitle: string;
  serviceKey: ServiceKey;
}

// TODO: refactor useService hook for better readability
const useService = ({ serviceTitle, serviceResource, serviceType, serviceKey }: Params) => {
  const [oAuthLink, setOAuthLink] = useState('');

  const handleOAuthProceedClick = useCallback(() => {
    window.location.href = oAuthLink;
  }, [oAuthLink]);

  const {
    handleSyncModalOpen,
    handleSyncAllModalOpen,
    handleDeleteModalOpen,
    handleOAuthModalOpen,
    handleSyncModalClose,
    handleSyncAllModalClose,
    handleRegisterModalOpen,
    handleRegisterModalClose,
    handleDeleteModalClose,
    handleOAuthModalClose,
    handleRestoreModalOpen,
    handleRestoreModalClose,
    renderModals,
  } = useModals({
    serviceTitle,
    serviceResource,
    serviceType,
    serviceKey,
  });

  const { selectedSyncService, handleSelectedSyncServiceChange } = useSelectedSyncService();

  const { one, getMemberIdentifiers } = useMembers();

  const { info, memberInfo } = useInfo({ serviceKey });

  const { serviceFieldsData } = useServiceFieldsData({
    serviceType,
    serviceResource,
    isRegistrable: !info.length,
  });

  const { profile } = useProfile();

  const { syncField } = useSyncField();

  const { syncAll } = useSyncAll();

  const { getOAuthLink, isLoading: isOAuthLinkLoading } = useOAuthLink();

  const currentServiceExternalId = useMemo(() => {
    const personsIds = getMemberIdentifiers();
    return personsIds[serviceKey];
  }, [getMemberIdentifiers, serviceKey]);

  const { archive, unArchive, remove, restore } = useServiceActions();

  const isOAuthed = useMemo(
    () => Object.entries(profile.serviceAuth).some(([key, value]) => key === serviceType && value),
    [profile.serviceAuth, serviceType],
  );

  const shouldAuth = useMemo(
    () => Object.entries(profile.serviceAuth).some(([key, value]) => key === serviceType && !value),
    [profile.serviceAuth, serviceType],
  );

  const handleArchiveConfirm = useCallback(() => {
    archive({ service: serviceType, resource: serviceResource, externalId: currentServiceExternalId });
  }, [archive, currentServiceExternalId, serviceResource, serviceType]);

  const handleUnArchiveConfirm = useCallback(() => {
    unArchive({ service: serviceType, resource: serviceResource, externalId: currentServiceExternalId });
  }, [currentServiceExternalId, serviceResource, serviceType, unArchive]);

  const handleDeleteConfirm = useCallback(() => {
    remove({ service: serviceType, resource: serviceResource, externalId: currentServiceExternalId, serviceKey });
  }, [currentServiceExternalId, remove, serviceKey, serviceResource, serviceType]);

  const handleRestoreConfirm = useCallback(() => {
    restore({ service: serviceType, resource: serviceResource, externalId: currentServiceExternalId });
  }, [currentServiceExternalId, restore, serviceResource, serviceType]);

  const handleAuthenticateClick = useCallback(async () => {
    const link = await Promise.resolve(getOAuthLink(serviceType));
    setOAuthLink(link);
    return handleOAuthModalOpen();
  }, [getOAuthLink, handleOAuthModalOpen, serviceType]);

  const handleSyncFieldClick = useCallback(
    (params: SyncFieldClickParams) => {
      handleSelectedSyncServiceChange(params);
      handleSyncModalOpen();
    },
    [handleSelectedSyncServiceChange, handleSyncModalOpen],
  );

  const handleSyncFieldConfirm = useCallback(
    async (serviceToSync: ServiceToSync) => {
      const { key, resource, field, type } = serviceToSync ?? {};

      if (key && resource && field && type) {
        const syncToExternalIdValue = String(one[key]?.externalIdValue);

        if (syncToExternalIdValue) {
          const { externalIdValue } = memberInfo;

          const syncFrom = {
            service: serviceType,
            resource: serviceResource,
            externalIdValue: String(externalIdValue),
          };

          const syncTo = { service: type, resource, externalIdValue: syncToExternalIdValue };

          const syncFieldParams = { field, from: syncFrom, to: syncTo };

          syncField(syncFieldParams);

          handleSyncModalClose();
        }
      }
    },
    [handleSyncModalClose, memberInfo, one, serviceResource, serviceType, syncField],
  );

  const handleSyncAllConfirm = useCallback(() => {
    const personsIds = getMemberIdentifiers();
    const mainServicePersonId = personsIds[serviceKey];

    const recipientServices = Object.entries(personsIds)
      .filter(([key]) => key !== serviceKey)
      .reduce((prevValue, [key, value]) => ({ ...prevValue, [key]: value }), {});

    const mainService = { serviceName: serviceKey, personIdentifier: { [serviceKey]: mainServicePersonId } };
    const syncAllParams = { mainService, recipientServices };

    syncAll(syncAllParams);

    handleSyncAllModalClose();
  }, [getMemberIdentifiers, handleSyncAllModalClose, serviceKey, syncAll]);

  return {
    isOAuthed,
    shouldAuth,
    info,
    memberInfo,
    handleSyncFieldClick,
    handleSyncAllModalOpen,
    isOAuthLinkLoading,
    handleAuthenticateClick,
    renderModals,
    selectedSyncService,
    handleSyncFieldConfirm,
    handleSyncAllConfirm,
    handleOAuthProceedClick,
    serviceFieldsData,
    handleRegisterModalClose,
    handleDeleteModalClose,
    handleOAuthModalClose,
    handleSyncModalOpen,
    handleDeleteModalOpen,
    handleOAuthModalOpen,
    handleSyncModalClose,
    handleSyncAllModalClose,
    handleRegisterModalOpen,
    handleArchiveConfirm,
    handleUnArchiveConfirm,
    handleDeleteConfirm,
    handleRestoreConfirm,
    handleRestoreModalOpen,
    handleRestoreModalClose,
    serviceExternalId: currentServiceExternalId,
  };
};

export default useService;
